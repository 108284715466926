.button {
  border-radius: 0;
  margin: 0;
  //   border-right: 'solid 1px white';
  border: 0;
  height: 60px;
  font: normal normal normal 20px / 32px 'SourceSans';
  //   border-top: red 5px solid;
  background-color: #052e37;
  transition: background-color 0.3s;
  cursor: pointer;
}
.button:hover {
  background-color: #bfce40;
  color: #052e37;
}
// h1 {
//   max-width: max-content !important;
//   margin-left: unset !important;
//   margin-right: unset !important;
// }
// .button:active {
//   background-color: #bfce40;
//   color: #052e37;
// }
