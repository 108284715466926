#main .breadcrumbs {
    height: 60px;
    padding: 15px 0;
    background-color: $white !important;
    border-bottom: 1px solid $midnight-green-10;

    @media screen and (max-width: $tablet-breakpoint) {
        display: none;
    }

    .breadcrumb {
        .section {
            font-size: 16px;
            margin: 0 10px 0 10px;
            color: $midnight-green !important;

            &.active {
                font-weight: normal;
            }
        }
        .divider {
            transform: none;

            &::before {
                content: none;
            }
        }
    }

    .home {
        height: 12px;

        svg {
            vertical-align: top;
        }
    }
}
