:root {
  --base-padding: 15px 20px 17px 20px;
}

.button {
  /* padding: 15px 20px 17px 20px; */
  padding: var(--base-padding);
  border: none;
  /* border-radius: 5px; */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #052e37;
  color: #ffffff;
  height: 60px;
  border-right: #ffff 1px solid;
  font: 20px / 32px 'SourceSans';
  flex: 1 1 auto;
}

.button:hover {
  background-color: #bfce40;
  color: #052e37;
}

.active {
  background-color: #bfce40;
  color: #052e37;
}

.button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
@media (max-width: 1200px) {
  :root {
    --base-padding: 12px 18px 15px 18px;
  }
}

@media (max-width: 600px) {
  :root {
    --base-padding: 5px;
  }
}
/*
@media (max-width: 768px) {
  :root {
    --base-padding: 8px;
  }
} */

/*
@media (max-width: 576px) {
  :root {
    --base-padding: 5px;
  }
} */
