.legend {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
  padding: 0 1rem;
  background-color: #f3f5f5;
  align-items: center;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 1px;
}

.circle {
  border-radius: 50%;
  /* background-color: #3300b9; */
  border: 1px solid;
  aspect-ratio: 1 / 1;
}
.shortNameMag {
  display: none;
}
.legendChild {
  display: flex;
  align-items: center;
  gap: 5px;
}

.textLegendLabel {
  font-weight: bold;
  font-size: 20px;
  margin-left: 10px;
}
@media (max-width: 800px) {
  .magnitude {
    flex-direction: column;
  }
  .legendChild {
    align-items: flex-start;
  }
  .textLegendLabel {
    margin-left: 0;
  }
  .legend {
    flex-direction: column;

    align-items: flex-start;
  }
}
