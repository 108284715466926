@mixin responsive-footer-container($min-height) {
    min-height: $min-height;
    margin: 0;
    padding: 0;

    > .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
        min-height: $min-height;
        padding: 60px 80px;
    }
}

#footer {
    font-size: 20px;

    h2 {
        font-size: 26px;
        color: $white;
        font-weight: bold;
        line-height: normal;
    }
    ul {
        list-style: none;

        li {
            line-height: normal;
        }
    }
    a {
        color: $white;

        &:hover {
            text-decoration: underline;
        }
    }

    .footer-prev {
        background-color: $midnight-green-muted;
        @include responsive-footer-container(400px);

        .footer-menu {
            align-items: flex-start;

            .footer-item-wrapper {
                text-align: left;

                h2 {
                    margin-bottom: 0;
                }

                a:hover {
                    text-decoration: underline;
                }

                &.participate a {
                    display: flex;
                    align-items: center;

                    svg.icon {
                        margin-right: 10px;
                    }
                }

                .footer-menu-header {
                    display: inline-block;
                }

                svg.accordion-open, svg.accordion-close {
                    display: none;
                }

                .footer-submenu {
                    margin-top: 27px;
                    padding-left: 0;
                }

                .footer-subitem-wrapper {
                    line-height: 32px;
                    min-height: 32px;
                }
            }
        }
    }
    .footer {
        text-align: left;
        background-color: $midnight-green;
        color: $white;
        @include responsive-footer-container(320px);

        .footer-logo {
            margin: 0;
            flex-shrink: 0;

            img {
                width: 260px;
            }
        }

        .contact, .social-media {
            display: flex;
            flex-direction: column;
        }

        > .container {
            gap: 60px;
        }

        .footer-links {
            margin-top: auto;
            flex-wrap: nowrap;
            justify-content: flex-start;

            li {
                border-color: $lime;
                border-right-width: 2px;
                padding: 0 15px;
                white-space: nowrap;

                &:first-of-type {
                    padding-left: 0;
                }
            }

            a {
                color: $white;
                font-size: 16px;
            }
        }

        .social-media-actions {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 16px 50px;

            .social-media-action a {
                display: flex;
                align-items: center;
                gap: 8px;

                svg * {
                    transition: fill 100ms ease-in-out;
                }

                &:hover {
                    svg * {
                        fill: $white;
                    }
                }
            }
        }
    }

    // allow footer wrapping if content exceeds container limits
    @media only screen and (max-width: 1400px) {
        .footer > .container .social-media .social-media-actions {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
        }

        .footer > .container .footer-links {
            flex-wrap: wrap;
            margin-left: -15px; // subtract li padding to allow smooth wrapping
            padding-top: 20px;

            > li:first-of-type {
                padding-left: 15px;
            }
        }
    }

    // tablet
    @media only screen and (max-width: $computer-breakpoint) {
        .footer-prev {
            height: auto!important;

            .footer-menu {
                flex-wrap: wrap;

                .footer-item-wrapper {
                    flex: 1 1 200px;
                }
                .footer-menu-header {
                    margin-bottom: 0;
                }
                .footer-submenu {
                    margin-bottom: 60px;
                }
            }
        }
        .footer  {
            > .container {
                flex-wrap: wrap;
                gap: 50px 50px;
                padding: 60px 40px !important;

                .footer-logo, .contact {
                    flex: 1 1 calc(50% - 50px);
                }
                .social-media {
                    margin-left: auto;
                    width: 50%;
                    min-height: 280px;
                    padding-left: 25px;

                    .social-media-actions {
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr 1fr 1fr;
                        width: 100%;
                        max-width: 320px;
                        gap: 16px 0;
                    }
                }

                .footer-links {
                    position: relative;
                    bottom: 0;
                    margin-top: 30px;
                    padding-top: 0;
                }
            }
        }
    }

    @media only screen and (max-width: $tablet-breakpoint) {
        .footer-menu-header {
            font-size: 26px!important;

            h2 {
                font-size: 26px!important;
            }
        }
    }

    // mobile
    @media only screen and (max-width: 650px) {
        .footer-prev {
            .footer-listing {
                padding-right: 40px!important;
                padding-left: 40px!important;
            }
            .footer-menu {
                flex-direction: column;
                border-top: 1px solid #fff;

                .footer-item-wrapper > div {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    min-height: 60px;
                    align-items: center;
                }
                .footer-menu-header {
                    width: 100%;
                }
                .footer-item-wrapper {
                    width: 100%;
                    min-height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: unset;
                    flex-direction: column;
                    border-bottom: 1px solid $white;

                    svg.accordion-open {
                        display: inline-block!important;
                        padding: 20px;
                        transform: translateX(20px);
                    }

                    .footer-submenu {
                        width: 100%;
                        max-height: 0;
                        margin: 0;
                        transition: max-height 300ms ease-in-out, opacity 150ms ease-in-out;
                        overflow: hidden;
                        opacity: 0;

                        &.open {
                            max-height: 500px;
                            opacity: 1;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        .footer  {
            .footer-logo, .contact, .social-media {
                flex: 1 1 100% !important;
                padding-left: 0 !important;
            }
            .footer-logo img {
                margin: auto;
            }
            .social-media {
                margin-left: 0 !important;
            }
        }
    }

}
