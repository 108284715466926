#page-document .blocks-group-wrapper > .block.__link, .block.__link {
    &::before {
        content: none!important;
    }
    &.next--is--link {
        margin-bottom: 20px;
    }
    .container {
        max-width: $default-container-width;
        margin-right: auto;
        margin-left: auto;

        &:has(+.container) {
            margin-bottom: 20px;
        }
        a.external::after {
            content: none;
        }

        .button.link {
            position: relative;
            padding-top: 13px;
            padding-bottom: 13px;
            width: 100%;
            background: none;
            border-top: 1px solid $midnight-green-30;
            border-bottom: 1px solid $midnight-green-30;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
            text-align: left;
            padding-left: 20px;
            @include body-text-styles;

            > span {
                display: block;
                line-height: 32px;
                max-width: calc(100% - 50px);
            }

            &:hover {
                background: $midnight-green-5;

                div {
                    background-color: $midnight-green;
                    transition: all 300ms;
                }
            }

            div {
                position: absolute;
                top: 0;
                right: 0;
                width: 60px;
                height: 100%;
                background-color: $midnight-green-30;
                text-align: center;
                overflow: hidden;

                svg {
                    position: relative;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);

                    * {
                        fill: $white;
                    }
                }
            }
        }
    }
}

#page-document .blocks-group-wrapper > .block.__link {
    @include layout-container-width();
    @include adjustMarginsToContainer($default-container-width);
}
