.hitliste {
  /* margin-top: 2rem; */
  overflow: hidden;
}
.hitliste .hdDiv {
  display: flex;
  align-items: center;
  align-content: space-around;
  justify-content: space-between;
}
.weatherprognoseTable_div {
  overflow-x: auto;
  overflow: hidden;
}
.tableNavigation {
  display: flex;
  margin-bottom: 1rem;
  overflow-x: auto;
  padding: 10px 0;
}

.tableNavigation span {
  white-space: nowrap;
}
.headerDivMargin {
  padding-top: 2rem;
  border-top: 3px solid #bfce40;
}
.headerDivMargin .pMargin {
  margin: 0 !important;
}
.header {
  margin-top: 20px;
  margin-bottom: 20px;
}
