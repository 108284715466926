/* TextWithBorder.module.scss */
.textWithBorder {
  max-width: 760px;
  box-sizing: border-box; /* Ensure padding doesn't affect total width/height */
  display: flex;
  padding-top: 13px;
  position: relative;
  padding-top: 13px;
  width: 100%;
  background: none;
  border-top: 1px solid #9babaf;
  border-bottom: 1px solid #9babaf;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  text-align: left;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 400;
  color: #052e37;
  padding-bottom: 13px;
}
// a:hover {
//   color: unset !important;
// }

.textWithBorder:active {
  border-top: 2px solid #9babaf;
  border-bottom: 2px solid #9babaf;
}
.textWithBorder:focus {
  border-top: 2px solid #9babaf;
  border-bottom: 2px solid #9babaf;
}
.textWithBorder span {
  width: calc(100% - 60px);
}
.textWithBorder:hover {
  cursor: pointer;
  color: #052e37;
  // background: #f3f5f5;
  .svgIconBackground {
    background-color: #052e37;
    // background: #e6eaeb;
  }
}
.svgIconBackground {
  background-color: #9babaf;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  // z-index: 2;
  // margin: -1px;
}
// .svgIconBackground::before {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: #9babaf;
//   transition:
//     transform 0.3s ease,
//     opacity 0.3s ease;
//   transform: scale(1);
//   transform-origin: right center;
//   opacity: 1; /* Fully visible by default */
// }
// .svg_icon {
//   z-index: 2;
// }
// @media (max-width: 1030px) {
//   // .textWithBorder {
//   //   width: auto;
//   //   // height: auto;
//   //   // flex-direction: column;
//   //   // align-items: self-start;
//   // }
//   .svgIconBackground {
//     position: inherit;
//   }
// }
