@mixin body-text-styles {
    font-size: 20px;
    font-weight: normal;
    color: $midnight-green;
}

@mixin h2-styles {
    font-size: 43px;
    line-height: 57px;
    font-family: $header-font;
    font-style: normal;
    font-weight: bold;
    color: $midnight-green;
}
h2, .block .heading-wrapper h2:last-child {
  @include h2-styles;
}

@mixin h3-styles {
    font-size: 34px;
    line-height: 48px;
    font-family: $header-font;
    font-style: normal;
    font-weight: bold;
    color: $midnight-green!important;
}
h3, .heading-wrapper h3 {
    @include h3-styles;
}

@mixin h4-styles {
    font-size: 26px;
    line-height: 40px;
    font-family: $header-font;
    font-style: normal;
    font-weight: bold;
    color: $midnight-green!important;
}
h4 {
    @include h4-styles;
}

@mixin h5-styles {
    font-size: 22px;
    font-family: $header-font;
    font-style: normal;
    font-weight: bold;
    color: $midnight-green!important;
}
h5 {
    @include h5-styles;
}

@mixin text-styles {
    font-size: 20px;
}

@media screen and (max-width: $tablet-breakpoint) {
    .blocks-group-wrapper {
        h1.documentFirstHeading {
            font-size: 40px!important;
            line-height: 52px!important;
            margin-top: 60px!important;
        }
    }

    h2, .block .heading-wrapper h2:last-child {
        font-size: 35px!important;
        line-height: 47px!important;
    }
    h3, .heading-wrapper h3 {
        font-size: 30px!important;
        line-height: 42px!important;
    }
    h4 {
        font-size: 25px!important;
        line-height: 37px!important;
    }
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
}

body, p, a, li {
    font-family: "SourceSans";
    font-style: normal;
    font-size: 20px;
}

li {
    line-height: 32px;
}

h1.documentFirstHeading {
    font-size: 56px!important;
    line-height: 70px!important;
    margin-top: 120px!important;
}

p {
    line-height: 33px;
}

li::marker {
    font-size: 25px;
}


#page-document .blocks-group-wrapper > p.documentDescription {
    margin-top: 16px;
    margin-bottom: $block-vertical-space;
    @include h5-styles;
}
#page-document h1:has(+ p.documentDescription) {
    margin-bottom: 0;
}

.content-area p > a, .content-area li > a, .content-area .breadcrumb a,
.breadcrumbs p > a, .breadcrumbs li > a, .breadcrumbs .breadcrumb a {
    color: $midnight-green !important;
    text-decoration: underline;

    &:hover {
        text-decoration: none!important;
    }
}
