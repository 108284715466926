/* Button.module.scss */
.customButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px; /* Add padding instead of fixed width/height */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  // &:hover {
  //   background-color: brightness(0.9); /* Adjust the hover effect */
  // }
}

.label {
  margin-left: 8px; /* Adjust the spacing between icon and label */
}
