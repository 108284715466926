@import 'variables-custom.scss';

// mobile Navigation
.header-wrapper .mobile-nav {
    margin-bottom: 45px;
    order: 1;

    .hamburger-wrapper {
        padding-bottom: 0;
        height: max-content;

        .hamburger-toggler {
            background-color: transparent!important;
            border: none!important;
        }

        .hamburger {
            width: 32px;
        }
    }

    .hamburger-inner:before, .hamburger-inner:after,
    .hamburger:before, .hamburger:after {
        background: #fff;
    }

    > div.menu-drawer {
        box-shadow: 0px 4px 15px 20px #00000061;
    }

    div.menu-drawer {
        height: calc(100vh - 190px) !important;
        background-color: $white;
        top: 160px;
        margin-right: 0;
        padding: 0;

        .mobile-tools {
            display: none;
        }

        ul.sections {
            padding: 0;
            background-color: $midnight-green-10;

            li {
                border-color: $midnight-green-30;
                padding: 0;
                margin: 0;

                &.current {
                    background-color: $white;

                    &::before {
                        left: 0;
                        border-color: $lime;
                    }
                }
                &.header {
                    @include h4-styles;
                    display: block;
                    padding-left: 40px;
                    line-height: 80px;
                    font-weight: bold;

                    a {
                        @include h4-styles;
                        line-height: 80px;
                        font-weight: bold;
                        padding-left: 0;
                    }
                }
                &:not(.header) a {
                    @include text-styles;
                    line-height: 50px;
                }

                a {
                    padding-left: 40px;

                    > svg {
                        background-color: $midnight-green-30;
                        padding: 17px 15px;
                        height: 16px!important;

                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }

    .search-header {
        background-color: $white;

        > button {
            position: absolute;
            padding: 0!important;
            height: 94px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            @media only screen and (max-width: 400px) {
                span {
                    display: none!important;
                }
            }

            &:not(.close) {
                left: 0;
                margin-left: 20px;
            }
            &.close {
                right: 0;
                color: $midnight-green!important;
                margin-right: 20px;

                span {
                    margin-right: 12px;
                }
                svg {
                    margin-right: 0;
                }
            }

            span {
                font-size: 20px!important;
                font-weight: normal!important;
                padding-top: 0!important;
            }

            svg {
                height: 16px!important;
                margin-right: 12px;
                margin-top: 5px;
            }
        }
    }
}

// responsive header changes computer and down
@media only screen and (max-width: $computer-breakpoint) {

    .header-wrapper > .search {
        height: 30px;

        .search-bar {
            display: none;
        }
    }

    .search-wrapper {
        display: block!important;
        height: 94px;

        .search-button {
            margin: 2px 0;

            span {
                margin-top: 2px;
            }
        }

        .search {
            color: $midnight-green;
            padding: 15px 0!important;

            button {
                margin: 0!important;
                width: auto!important;
                border-radius: 5px!important;

                &:hover {
                    border: 1px solid $midnight-green!important;
                }
            }
        }

        .search-bar {
            position: relative!important;
            top: 0!important;
            transition: width .1ms ease-in-out !important;
            padding: 0!important;
            height: auto!important;
            width: 100px!important; // make responsive
            background-color: $white!important;
            z-index: 1!important;

            .searchbox {
                display: inline-flex!important;
                justify-content: center;
                width: 100%;
                height: auto;

                span {
                    font-size: 20px!important;
                    font-weight: normal!important;
                    padding-top: 0!important;
                }
            }

            &.active {
                width: calc(100% - 40px)!important;

                .searchbox {
                    justify-content: flex-end;

                    input[name="SearchableText"] {
                        width: 100%;

                        &:focus-visible {
                            outline: 1px solid $midnight-green;
                        }
                    }

                    button {
                        padding: 0px 15px!important;
                    }
                }
            }
        }
    }
}

@media (max-width: 1640px) {
    .header-wrapper > .search {
        padding-left: 20px;
        padding-right: 20px;

        .search-bar > form {
            width: auto;
            max-width: 100%;

            .searchbox {
                width: 100%;
            }
        }
    }
}

// responsive header changes computer and up
@media only screen and (min-width: $computer-breakpoint) {
    .logo-nav-wrapper {
        margin-top: 60px;
    }

    .header-wrapper > .search {
        position: absolute !important;
        z-index: 20 !important;
        @include adjustMarginsToContainer($layout-container-width);

        .search-bar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0;
            top: 0px;
            left: 0;
            height: 60px;
            transition: height 600ms ease-in-out;

            form.search {
                width: 100%;
            }

            .searchbox {
                height: 60px;

                input[name="SearchableText"] {
                    width: 1140px;
                    // width: 100%;
                    max-width: calc(100% - 153px);
                }
            }
        }
    }
}

// header
.header-wrapper {
    padding: 0;
    background-color: $midnight-green;

    > .search {
        width: 100%;
        background-color: $lime;
    }

    .search-bar {
        .searchbox {
            button {
                align-items: center;
                border-radius: 5px;
                border: none;
                height: 60px;
                margin-left: auto;
                padding-left: 20px;
                font-size: 20px;
                transition: background-color 200ms ease-in-out;
                width: 133px;

                > span {
                    color: $white;
                }
                svg {
                    path {
                        fill: $white;
                    }
                }
            }
        }

        &.transitioning {
            pointer-events: none;
        }

        &.active {
            height: 120px;

            .search-button {
                display: none;
            }

            .searchbox {
                input[name="SearchableText"] {
                    opacity: 1;
                    display: inline-block;
                }
                svg {
                    path {
                        fill: $white;
                    }
                }
                button {
                    display: inline-flex;
                    background-color: #052E37;

                    &:hover {
                        background-color: $white;

                        > span{
                            color: #000;
                        }

                        svg {
                            path {
                                fill: #052E37;
                            }
                        }
                    }
                }
            }
        }
        &:not(.active) {
            button:not(.search-button) {
                display: none;
            }
        }
    }

    .search-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 60px;
        width: 133px;
        font-size: 20px;
        margin-left: auto;

        &:hover {
            cursor: default;
            text-decoration: underline;
        }
    }

    .searchbox {
        position: relative;
        padding: 0;
        margin: 0;
        font-size: 1em;
        border-bottom: 0;
        text-wrap: nowrap;

        input[name="SearchableText"] {
            display: none;
            opacity: 0;
            transition: opacity 600ms ease-in-out;
            font-size: 1rem;
            padding: 0.5rem 1rem;
            height: 60px;
            margin-right: 20px;
            border: 0;
            font-size: 20px;

            &:focus-visible {
                outline: 1px solid $midnight-green;
            }
        }

        button {
            align-items: center;
            border-radius: 5px;
            border: none;
            height: 60px;
            margin-left: auto;
            padding-left: 20px;
            font-size: 20px;
            transition: background-color 200ms ease-in-out;
            width: 133px;

            > span {
                color: $white;
            }
            svg {
                path {
                    fill: $white;
                }
            }
        }
    }

    .submenu:has(.menu-drawer-enter-done), .submenu:has(.menu-drawer-enter-active) {
        > .submenu-inner {
            > ul {
                height: 0;
                overflow: hidden;
            }
            > ul > li > a {
                opacity: 0!important;
            }
        }
    }

    .navigation .item {
        color: $white;
        border-bottom: 0;

        & ~ .submenu-wrapper {
            opacity: 0;
            transition: opacity 300ms;
        }
        &.active ~ .submenu-wrapper {
            opacity: 1;
        }

        &::before {
            border-bottom: 0;
        }

        &.active {
            color: $lime;
        }
    }

    .header-wrap div.header {
        height: 220px;

        .logo-nav-wrapper {
            height: 160px;
            max-height: 160px;
            justify-content: space-between;
            padding: 0;
            align-items: flex-end;

            .navigation {
                align-items: flex-end;
                justify-content: flex-end;
            }

            .logo, .navigation {
                align-self: flex-end;
            }

            .logo {
                margin-bottom: 35px;

                img {
                    height: 80px;
                    object-fit: contain;
                }
            }

            @media (max-width: 500px) {
                .logo img {
                    height: 60px;
                }
            }
            @media (max-width: 420px) {
                .logo img {
                    height: 50px;
                    width: 50px;
                    object-fit: cover;
                    object-position: left;
                }
            }
            ul.desktop-menu {
                position: relative;
                height: 60px;

                > li {
                    position: relative;
                    margin-right: 10px;
                    border-radius: 5px 5px 0px 0px;
                    transition: background-color 200ms;

                    &:last-of-type {
                        margin-right: 25px;
                    }

                    &:has(.submenu.active) {
                        background-color: $lime;

                        > button.item {
                            color: $midnight-green!important;
                        }
                    }

                    > button.item {
                        margin: 0;
                        padding: 12px 15px 15px 15px;
                        font-weight: normal;
                        font-size: 20px;
                        transition: color 200ms;

                        &:hover {
                            color: $lime;
                        }
                    }
                }

                .submenu-wrapper .subitem-wrapper {
                    width: unset;
                }
            }

            .language-selector {
                height: 100%;
                display: flex;
                align-items: flex-end;
                margin-right: 0;
                margin-bottom: 45px;

                .active-language {

                    a {
                        font-size: 20px;
                        font-weight: normal!important;
                    }

                    > div:not(.dropdown) {
                        padding-left: 20px;
                        border-left: 2px solid $lime;
                        text-transform: uppercase;
                        color: $white;
                        cursor: pointer;

                        &::after {
                            content: none;
                        }

                        svg {
                            width: 16px;
                            padding-left: 12px;
                        }
                    }
                }

                .dropdown {
                    display: none;
                    position: absolute;
                    min-width: 80px;
                    margin-top: 15px;
                    background-color: $midnight-green-10;
                    z-index: 1;

                    &.open {
                       display: block;
                    }

                    a {
                        color: $midnight-green;
                        display: block;
                        padding: 10px 20px;

                        &:not(:last-child) {
                            border-bottom: 1px solid $midnight-green;

                            &::after {
                                content: none;
                            }
                        }

                        &:hover {
                            background-color: $white;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $computer-breakpoint) {
        .searchbox {
            height: 30px;
        }

        .language-selector {
            order: 0;

            div.active-language > div:not(.dropdown) {
                border-left: 0!important;
                border-right: 2px solid #BFCE40;
                padding-right: 20px;
            }

        }

        div.header {
            height: 160px!important;
            padding: 0px 0px!important;
        }
    }
}

// main navigation
.navigation {
    margin-bottom: 20px;

    ul.desktop-menu {
        .submenu {
            padding: 0;
            background: $midnight-green-10;
            margin-top: 80px;
            box-shadow: none;
            padding-bottom: 50px;

            .submenu-inner {
                max-height: 440px;
                overflow: auto;
            }

            .submenu-header {
                @include h4-styles;
                display: block;
                padding: 0 40px;
                line-height: 80px;
                font-weight: bold;
                color: $midnight-green;
            }

            .close {
                z-index: 20;
                font-size: 20px;
                background-color: transparent;
                right: 40px;
                color: $midnight-green!important;

                &:hover {
                    text-decoration: underline;
                }

                span {
                    margin-right: 10px;
                    color: $midnight-green!important;
                }
            }
        }

        .submenu-header {
            &:hover {
                background-color: $white;
                box-shadow: 0px 1px 0px 0px inset $midnight-green-30;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 10px;
                    height: 100%;
                    background-color: $lime;
                }
            }

            h2 {
                margin-left: 0;
                font-weight: bold;
                color: $midnight-green!important;
            }
        }

        div.submenu-wrapper {
            width: 600px;
            top: 100%;
            margin: 0;
            left: 100%;
            right: 0;
            transform: translateX(-100%);
            background-color: $white;

            .menu-drawer {
                width: unset;
                height: unset;
                left: 0;
                top: 0;
                background-color: transparent;
                z-index: 2;
                padding: 0;

                .subitems {
                    background-color: $midnight-green-10;
                    padding-bottom: 50px;
                }

                .back {
                    line-height: 80px;
                    background-color: transparent;

                    button {
                        line-height: 40px;
                        background: transparent;
                        border: none;
                        color: $midnight-green;
                        padding-left: 40px;
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline;
                        }

                        svg {
                            margin-right: 10px;
                        }
                    }
                }
            }

            ul {
                padding: 0;
                background-color: transparent;
                flex-direction: column;

                li.header {
                    color: black;
                }

                li:not(.submenu-header) {
                    padding: 0;
                    margin: 0;
                    border-bottom: 1px solid $midnight-green-30;
                    padding-left: 30px;
                    border-left: 10px solid transparent;
                    transition: background-color 200ms, border-color 200ms;

                    &:first-of-type {
                        border-top: 1px solid $midnight-green-30;
                    }

                    &:hover {
                        background-color: $white;
                        border-left-color: $lime;
                    }

                    > a {
                        @include text-styles;
                        line-height: 50px;
                        padding: 0;
                        font-weight: normal;
                        width: 100%;
                        justify-content: space-between;
                        color: $midnight-green;
                    }

                    > a > svg {
                        background-color: $midnight-green-30;
                        padding: 17px 15px;
                        height: 16px!important;

                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }
}
