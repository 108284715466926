.tawesnavbar {
  /* padding: 10px; */
}

.tawesnavbarList {
  list-style: none;
  display: flex;
  /* gap: 15px; */
  margin: 0;
  padding: 0;
  background-color: #052e37;
}

.tawesnavbarItem {
  color: #333;
  display: flex;
}

.evaluatedOrNotData {
  width: 100%;
  display: flex;
}
.tawesnavbar-direction {
  flex-direction: column;
}

/* for Mobile */
.mobileMenuButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #052e37;
  right: 0;
  display: none;
}
.spanParameter {
  display: none;
  font: normal normal bold 26px/41px 'SourceSans';
  color: #052e37 !important;
  background-color: #e6eaeb;
}
.navButtonSpan {
  /* cursor: pointer; */
  color: white;
  display: flex;
  justify-content: space-between;
  background-color: #e6eaeb;
}
.tawesnavbarListMobile {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  left: 0;
  right: 0;
  background-color: #e6eaeb;
  /* border: 1px solid #ccc; */
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.tawesnavbarItemMobile {
  /* Styles for individual items in the mobile menu */
  padding: 1px; /* Adjust padding as needed */
  border-bottom: 1px solid #ccc; /* Optional: Add separator between items */
}

.spanDropDownItem {
  border-bottom: #9babaf 1px solid;
  cursor: pointer;
  padding-left: 20px !important;
  padding-top: 12.5px !important;
  padding-bottom: 12.5px !important;
  width: 100%;
}
.spanDropDownItem:hover {
  background-color: #fff;
}

.tawesnavbarListSmall,
.tawesnavbarListSuperSmall {
  display: none;
}
/* Media query for smaller screens */
@media (max-width: 768px) {
  /* .tawesnavbar {
    display: block;
  }

  .tawesnavbarList {
    display: none;
    flex-direction: column; 
  }

  .mobileMenuButton {
    display: inline-block;
  }
  .spanParameter {
    display: inline-block;
  }
  .tawesnavbarItem {
    margin-right: 0; 
  } */
  /* button {
    width: 100%;
  } */

  .tawesnavbarList {
    display: none;
  }
  .tawesnavbarListSmall {
    display: flex;
  }
  .buttonFullWidth {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .tawesnavbarList {
    display: none;
  }
  .tawesnavbarListSmall {
    display: none;
  }
  .tawesnavbarListSuperSmall {
    display: flex;
  }
}
