.legend {
  display: flex;
  flex-direction: row;
  /* grid-column: 2 !important; */
  grid-row: 3 !important;
  /* padding: 1.5rem; */
  /* height: 100%; */
  /* overflow-y: auto; */
  flex-wrap: nowrap;
  overflow: hidden;
  width: auto;
  padding: 1rem;
  background-color: #f3f5f5;
  align-items: baseline;
  gap: 1rem;
  /* align-items: center; */
  /* position: absolute !important; */
}
.legendCenter {
  align-items: center;
}
.legendGridRow {
  grid-row: 4 !important;
}

/* .legendRow {
  display: flex;

  align-items: center;
  margin-bottom: 10px;
}

.legendCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
} */

.segmentWrapper {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.legendFlexpart {
  display: flex;
  flex-direction: row;
}
.legendFlexpartGap {
  gap: 1rem;
}
.flexCol {
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 0;
}
.timestamp {
  position: absolute;
  display: flex;
  right: 5px;
  bottom: 0;
  color: #052e37;
  font: normal normal bold 16px/32px 'SourceSans';
}
.slash {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: #bfce40;
}

.segment {
  width: 71px;
  height: 20px;
  margin-bottom: 4px;
  transition: width 0.2s ease-in-out;
}
.segmentNoMargin {
  margin-bottom: 0;
}
.withBorder {
  border: 1px solid #052e37;
}
.flexAlignCenter {
  align-items: center;
}

.label {
  font-size: 12px;
  text-align: center;
}
.legend .pClass:only-child {
  font: normal normal bold 16px/32px 'SourceSans';
  color: #052e37 !important;
  margin: 0 !important;
  margin-right: 20px !important;
  line-height: normal;
}
.legend p {
  margin: 0 !important;
  font-weight: bold;
}
.iconPclass {
  display: flex;
  align-items: self-start;
}
.iconPclassChild {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}
.legend .pClassSmall {
  font: normal normal normal 16px 'SourceSans';
  margin: 0 !important;
  font-weight: normal;
}

@media (max-width: 1640px) {
  /* Example media query for smaller screens */
  .segment {
    width: 50px;
  }
}
@media (max-width: 1200px) {
  /* Example media query for smaller screens */
  .segment {
    width: 40px;
  }
  /* p {
    margin-right: 10px !important;
  } */
}
@media (max-width: 1000px) {
  /* Example media query for smaller screens */

  .segment {
    width: 40px;
  }
  .legend {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.2rem;
  }

  /* p {
    margin-right: 10px !important;
  } */
}
@media (max-width: 600px) {
  /* Example media query for smaller screens */

  .segment {
    width: 30px;
  }
}
@media (max-width: 450px) {
  /* Example media query for smaller screens */

  .segment {
    width: 22px;
  }
  .legendFlexpart {
    width: 100%;

    /* overflow: auto; */
    flex-grow: 1;
    justify-content: space-between;
  }
}
