#page-document .blocks-group-wrapper > .block.teaser.has--boxsize--small,
#page-document .blocks-group-wrapper > .block.teaser.has--boxsize--large,
.block.teaser-listing div.teaser-listing-item,
.block.news-listing div.teaser-listing-item {
    max-width: var(--default-container-width);

    .teaser-item {
        position: relative;
        background-color: $midnight-green-5;
        padding: 0!important;
        border: 0!important;

        @media screen and (max-width: $tablet-breakpoint) {
            height: unset;
        }

        // @media screen and (max-width: $computer-breakpoint) {
        //     .arrow-link {
        //         right: 20px!important;
        //     }
        // }
        @media screen and (min-width: $tablet-breakpoint) {
            &:hover {
                .arrow-link {
                    transition: background-color 200ms ease-in-out;
                    background-color: $midnight-green;
                }
            }
        }

        .image-wrapper {
            height: 100%;
            margin-right: 0!important;
            border: 1px solid $midnight-green-5;
            transition: transform 200ms ease-in-out;
            margin-bottom: 0;

            @media screen and (min-width: $computer-breakpoint) {
                flex-shrink: 0;
            }
            @media screen and (max-width: $tablet-breakpoint) {
                // Mobile styles
                width: 100%;
                height: 260px;

                img {
                    width: 100%;
                }
            }
            @media screen and (min-width: $tablet-breakpoint) {
                // Tablet/Computer styles
                width: 280px;

                img {
                    width: auto;
                }
            }

            img {
                height: 100%;
                max-height: 100%;
                object-fit: cover;
                background-color: $white;
            }
        }

        .content {
            max-height: 100%;
            padding: 40px 60px 60px 40px;

            h2 {
                font-size: 26px!important;
                line-height: normal!important;
            }

            p {
                font-size: 20px;
                color: $midnight-green;
                line-height: 33px;
            }
        }

        .arrow-link {
            width: 60px;
            height: 60px;
            background: $midnight-green-30;
            position: absolute;
            bottom: 0;
            right: 0;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                path {
                    fill: $white;
                }
            }
        }
    }
}

.block.teaser.has--boxsize--small,
.block.teaser-listing div.teaser-listing-item,
.block.news-listing div.teaser-listing-item {
    .teaser-item {
        height: 280px;
    }
}

.block.teaser.has--boxsize--large {
    .teaser-item {
        height: 440px;
    }
}

// override kitconcept rule that sets line-height to 30px
@container (max-width: 768px) {
    .block.teaser.has--boxsize--small .content h2,
    .block.teaser.has--boxsize--large .content h2 {
        line-height: 36px;
    }
}
