/* For Highcharts ! */

.highcharts-container {
  -webkit-overflow-scrolling: touch !important;
  touch-action: auto !important;
}

.textAlignRight {
  text-align: right;
}
.custom-label {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.highcharts-tooltip-container {
  background-color: #052e37;
  font: normal normal normal 19px/30px 'SourceSans';
  width: auto;
  min-width: 300px;
  min-height: 92px;
  z-index: 11 !important;
}
/* .highcharts-root {
  width: 300px;
} */
.highcharts-label {
  font: normal normal normal 19px/30px 'SourceSans';
  width: 300px;
  height: 92px;
}

/* sind wind geschwindigkeiten! */
.highcharts-data-label-color-1 text {
  font: normal normal normal 19px/30px 'SourceSans';
  font-weight: lighter !important;
}

/* background for highcharts title temperatur */
.y-axis-title-background span {
  color: #fff;
  background-color: #a92a4e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px; /* Adjust padding as needed */
  width: 377px;
}
.y-axis2-title-background span {
  color: #fff;
  background-color: #2a47a9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px; /* Adjust padding as needed */
  width: 377px;
}
.y-axis3-title-background span {
  color: #fff;
  background-color: #052e37;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px; /* Adjust padding as needed */
  width: 50px;
}
