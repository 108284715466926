.weatherInfo {
  /* padding: 20px; */
  /* border: 1px solid #ccc;
  border-radius: 5px; */
  margin-bottom: 20px;
}
.weatherInfo p {
  font: 34px / 54px 'WeissenhofGrotesk';
  font-weight: 700;
  /* padding: 0; */
}
.weatherInfo .warnInfo {
  font: 34px / 54px 'WeissenhofGrotesk';
  font-weight: 700;
  background-color: #ff0100;
  color: #fff;
  /* padding: 0; */
}
.header {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.text {
  font-size: 1.2em;
  color: #666;
  line-height: 1.4;
}
