/* .rotate-1 {
  transform: rotate(22.5deg);
}
.rotate-2 {
  transform: rotate(45deg);
}
.rotate-3 {
  transform: rotate(67.5deg);
}
.rotate-4 {
  transform: rotate(90deg);
}
.rotate-5 {
  transform: rotate(112.5deg);
}
.rotate-6 {
  transform: rotate(135deg);
}
.rotate-7 {
  transform: rotate(157.5deg);
}
.rotate-8 {
  transform: rotate(180deg);
}
.rotate-9 {
  transform: rotate(202.5deg);
}
.rotate-10 {
  transform: rotate(225deg);
}
.rotate-11 {
  transform: rotate(247.5deg);
}
.rotate-12 {
  transform: rotate(270deg);
}
.rotate-13 {
  transform: rotate(292.5deg);
}
.rotate-14 {
  transform: rotate(315deg);
}
.rotate-15 {
  transform: rotate(337.5deg);
} */

.wind-0 {
  transform: rotate(0deg);
}

.wind-1 {
  transform: rotate(45deg);
}

.wind-2 {
  transform: rotate(90deg);
}

.wind-3 {
  transform: rotate(135deg);
}

.wind-4 {
  transform: rotate(180deg);
}

.wind-5 {
  transform: rotate(225deg);
}

.wind-6 {
  transform: rotate(270deg);
}

.wind-7 {
  transform: rotate(315deg);
}
.backgroundTooltop .pPopup {
  margin: 0 !important;
}
.backgroundTooltop .pPopupFirstChild {
  font: normal normal bold 19px/30px 'SourceSans';
  margin: 0 !important;
}

.backgroundTooltop .pPopupOtherChild {
  font: normal normal normal 19px/30px 'SourceSans';
  margin: 0 !important;
}
.lastChild {
  font-size: 1.2em;
}
.aClass {
  font: normal normal normal 19px/30px 'SourceSans';
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  /* pointer-events: none; */
}
.aClass:hover {
  /* pointer-events: none; */
  text-decoration: underline;
  color: unset;
}
