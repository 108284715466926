#page-document .blocks-group-wrapper > .block.heading.next--is--slate {
    margin-bottom: 0;

    h2, h3, h4, h5 {
        margin: 0;
        padding: 0;
    }

    h2 {
        margin-top: 110px;
        margin-bottom: 30px;
    }
    h3 {
        margin-top: 110px;
        margin-bottom: 16px;
    }
    h4 {
        margin-top: 60px;
        margin-bottom: 27px;
    }
    h5 {
        margin-top: 60px;
        margin-bottom: 27px;
    }
}

// page subtitle
#page-document .blocks-group-wrapper > .block.heading.page_subtitle .heading {
    margin-top: 16px;
    margin-bottom: $block-vertical-space;
}

#page-document h1:has(+ .block.heading.page_subtitle) {
    margin-bottom: 0;
}

#page-document .blocks-group-wrapper > .block.heading.heading_wide .heading-wrapper {
    @include layout-container-width();
    @include adjustMarginsToContainer($layout-container-width);
}