.backgroundWidget {
  background-color: #f3f5f5;
  border-left: 10px solid #bfce40;
}

.container {
  display: grid;
  justify-content: space-between;
  align-items: center;
  // padding: 20px;
  padding: 4rem 0rem 0 4rem;
  grid-column-gap: 70px;
  grid-template-columns: 1fr 1fr;
  .pTagBig {
    font: 34px / 54px 'WeissenhofGrotesk';
    font-weight: 700;
    margin-bottom: 10px;
  }
  .title {
    font: normal normal bold 20px / 32px 'SourceSans';
  }
}
.container p {
  font: normal normal normal 20px / 32px 'SourceSans';
}
.flexRow {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 95px;

  span {
    margin: 0rem auto 1rem;
    font: normal normal 20px/30px 'SourceSans';
    font-weight: 600;
    p {
      font: normal normal 16px/31px 'SourceSans';
      text-wrap: nowrap;
      // margin-bottom: 5rem;
    }
  }
}

.leftContent {
  flex: 1;
  width: 95%;
  max-width: 760px;
}

.text {
  font-size: 16px;
  margin-bottom: 20px;
}

.barsContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.barsRow {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.offsetExternalLink {
  margin-top: 65px;
  margin-bottom: 60px;
  grid-row: 2;
}

// .bar1 {
//   background-color: #ff6b6b; // Red
// }

// .bar2 {
//   background-color: #feca57; // Yellow
// }

// .bar3 {
//   background-color: #97CE40; // Green
// }

// .bar4 {
//   background-color: #54a0ff; // Blue
// }

// .bar5 {
//   background-color: #5f27cd; // Purple
// }

.labelsRow {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 14px;
}

.rightContent {
  // margin-left: 20px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  grid-row: span 2;
}

.image {
  width: 100%;
  height: auto;
  // object-fit: cover;
  // height: 300px;
  // width: auto;
  max-width: 100%;
  object-fit: contain;
}
.bar {
  flex: 1;
  min-height: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #97ce40;
}

.bar1 {
  background-color: #ffdb23;
}

.bar2 {
  background-color: #ff9500;
}

.bar3 {
  background-color: #ff0100;
}

.lightgray {
  background-color: #f3f5f5;
}

@media (max-width: 1200px) {
  .container {
    // flex-direction: column;
    grid-template-columns: 1fr;
    // padding: 0;
    align-items: baseline;
    gap: 0;
    // justify-items: center;
  }
  .barsContainer {
    margin-bottom: 3rem;
  }
  .offsetExternalLink {
    grid-row: 3;
    width: 100%;
  }
  .rightContent {
    grid-row: 2;
    width: fit-content;
  }
}
@media (max-width: 900px) {
  .barsRow {
    flex-direction: column;
  }
  .leftContent {
    width: 100%;
  }
  .container {
    padding: 1.2rem;
    justify-items: center;
  }
  .backgroundWidget {
    border-left: unset;
  }
}
