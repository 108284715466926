#page-document .blocks-group-wrapper > .accordion-block {
    margin-bottom: $block-vertical-space;

    span {
        color: $midnight-green!important;
    }

    div.active svg {
        * {
            fill: $midnight-green-30;
        }
    }

    > div > div {
        > span {
            font-size: 20px;
        }
        > svg {
            height: 16px!important;
            transform: none;
            flex-shrink: 0;
        }
    }

    .blocks-group-wrapper {
        padding-bottom: 40px!important;
        margin-bottom: 0!important;
    }
    .blocks-group-wrapper p {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.accordion-block .ui.styled.accordion .title.accordion-title,
.block-editor-accordion .ui.styled.accordion .title.accordion-title {
    padding: 16px 0;
}

.accordion-block .ui.styled.accordion,
.accordion-block .ui.styled.accordion .title.accordion-title,
.block-editor-accordion .ui.styled.accordion .title.accordion-title {
    border-color: $midnight-green-30!important;
    padding-left: 10px;
    padding-right: 10px;
}
