#page-document .block.search {
    .searchBlock-container {
        margin-left: 0!important;
        margin-right: 0!important;
    }

    @container (min-width: #{$computer-breakpoint}) {
        .searchBlock-facets > .row:has(.search-input-resultscount-sort) > .column {
            .search-input-resultscount-sort,
            .facets div.grid > div:has(.daterange-facet) {
                width: auto!important;
            }
        }
    }

    @container (max-width: #{$computer-breakpoint}) {
        .searchBlock-facets > .row:has(.search-input-resultscount-sort) > .column {
            .facets div.grid > div.column:has(.daterange-facet) {
                width: 100%!important;
            }
        }
    }

    .searchBlock-facets {
        @container (max-width: #{$largest-mobile-screen}) {
            gap: $block-vertical-space;

            > .row:has(.search-input-resultscount-sort) > .column {
                .search-input-resultscount-sort,
                .facets div.grid > div {
                    width: 100%!important;
                }
            }

            .search-input-resultscount-sort .search-wrapper {
                margin-bottom: 0!important;
            }
        }
        .DateRangePicker {
            width: 100%;

            .DateRangePickerInput {
                width: 100%;
                padding: 0!important;

                .DateInput {
                    width: calc(50% - 20px);
                }
                .DateRangePickerInput_clearDates {
                    display: none;
                }
            }
        }
        .search-input-resultscount-sort {
            margin-bottom: 0!important;
            flex-shrink: 0;
            width: auto;

            .search-wrapper {
                width: 100%;
                height: auto;
            }

            .search-input {
                height: auto;
                background-color: transparent;

                .search-header {
                    font-size: 20px;
                    margin-bottom: 15px;
                }

                .search-input-live-icon-button {
                    position: absolute;
                    top: unset;
                    right: 0;
                    bottom: 1px;
                    height: 58px;
                    margin-right: 1px;
                    padding: 10px;
                }
            }
            .search-input .ui.input {
                height: 60px;
            }
            .search-input .ui.input input {
                background-color: $white;
                border: 1px solid $midnight-green-30;
                border-radius: 5px;
                height: 60px;
                font-size: 20px;
                color: $midnight-green;
            }
        }
        > .row:has(.search-input-resultscount-sort) > .column {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-end;
            gap: 20px 40px;
            margin-bottom: $block-vertical-space;

            .search-input-resultscount-sort,
            .facets div.grid > div {
                padding: 0;
                margin-bottom: 0!important;
            }

            .facets, .facets > div {
                display: contents;
            }
        }
    }

    .facets {
        .react-select-container, .daterange-facet {
            .react-select__value-container {
                padding-left: 20px;
            }
            .react-select__menu {
                padding: 0px;
                box-shadow: 2px 2px 5px rgba(0,0,0,.1);
                background-color: $midnight-green-10;

                > * {
                    padding: 0px;
                }
                > * > * {
                    padding: 15px 20px;
                    font-size: 20px;
                    color: $midnight-green;
                    border-top: 1px solid $midnight-green-30;

                    &:hover {
                        background-color: $white;
                    }
                }
            }
            h4.ui.header {
                font-size: 20px;
            }
            .DateRangePickerInput_arrow_svg {
                margin: 0 8px;
            }
            .date-time-widget-wrapper .DateRangePicker .DateRangePickerInput {
                flex-shrink: 0;
            }
            .date-time-widget-wrapper .DateRangePicker .DateInput,
            .react-select__control {
                height: 60px;
                background-color: $midnight-green-10;

                .DateInput_input {
                    background-color: transparent;
                    border: none;
                    color: $midnight-green;
                    font-size: 20px;
                    padding: 0 20px;
                    margin-top: 20px;

                    &::placeholder {
                        font-size: 20px!important;
                        color: $midnight-green!important;
                    }
                }

                .react-select__placeholder {
                    font-size: 20px;
                    color: $midnight-green;
                }

                .react-select__indicators {
                    padding: 0 10px;

                    .react-select__indicator svg {
                        height: 35px!important;
                        fill: $midnight-green!important;
                    }
                }

                .react-select__multi-value {
                    background-color: $white;
                    font-size: 20px;
                    color: $midnight-green;
                }
            }
        }
    }
}
