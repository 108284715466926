.blocks-group-wrapper .infoBox,
.block-editor-infoBox div.block.infoBox {
    padding: 60px 80px;
    max-width: $default-container-width!important;

    &.lime {
        background-color: $lime-20;
    }
    &.red {
        background-color: $red-20;
    }

    h4:empty {
        display: none;
    }
}
