.project-view .block.__button .button.container.project-overview-link {
    margin-bottom: 0;
    margin-top: 0;

    a > button.ui.button {
        white-space: nowrap;
    }
}

.top-overview-button .block.__button .button.container.project-overview-link {
    margin-top: 60px;
    margin-bottom: 20px;
}

.project-view .block.__button:has(.button.container.project-overview-link) {
    margin-bottom: 0;
}

.project-view .top-overview-button + .blocks-group-wrapper {
    padding-top: 0px!important;
}

#page-document.project-view .blocks-group-wrapper > p {
    margin-right: auto!important;
}

@container(max-width: 1000px) {
    .project-overview-link {
        margin-right: 40px!important;
        margin-left: 40px!important;
    }
    .block.__project div.container {
        margin-right: 40px!important;
        margin-left: 40px!important;
    }
}
.project-view h1.documentFirstHeading {
    margin-top: 0!important;
}
#page-document > .block.__project {
    max-width: $default-container-width;
    margin-left: auto!important;
    margin-right: auto!important;

    .project-info-table {
        display: grid;
        grid-template-columns: auto auto;
        margin-bottom: $block-vertical-space;
        border-bottom: 2px solid $midnight-green-30;

        > div {
            padding: 20px;
            font-size: 20px;

            &:nth-child(4n-3), /* Odd row headers */
            &:nth-child(4n-2)  /* Odd row values */ {
                background-color: $midnight-green-5;
            }

            &:nth-child(4n-1), /* Even row headers */
            &:nth-child(4n)    /* Even row values */ {
                background-color: $white;
            }

            &.row-header {
                font-weight: bold;
                border-right: 2px solid $midnight-green-30;
            }
        }
    }

    .external-partners {
        display: flex;
        gap: 20px;
        max-width: var(--default-container-width);

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 140px;
            width: calc(25% - 20px);
            overflow: hidden;
        }

        img {
            width: 70%;
            height: 70%;
            object-fit: contain;
        }
    }
}
