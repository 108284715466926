/* WeatherTable.module.css */

.parentTableDiv {
  width: 100%;
  margin-bottom: 80px;
  overflow-y: auto;
}

.table {
  width: 100%;
  max-width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  font: 20px / 32px 'SourceSans';
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #ddd;
}
.thFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.styleIconDiv {
  width: fit-content;
  /* background-color: #a92a4e; */
  justify-content: center;
  align-items: center;
  display: flex;
  /* padding: 7px; */
  /* border-radius: 50%; */
}

.weatherSymbol {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.marginTop5 {
  margin-top: 5px;
}
.temperature {
  display: flex;
  justify-content: center;
}
.temperature .slash {
  margin-right: 1rem;
  color: #bfce40;
  margin-left: 1rem;
}
.flexRRSymb {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rrMargin {
  margin: 0 0.5rem;
}
.yellow {
  background-color: rgba(255, 255, 0);
}

.orange {
  background-color: rgba(255, 166, 0);
}

.red {
  background-color: rgba(255, 0, 0);
}

.lightgray {
  background-color: #f3f5f5;
}

@keyframes scrollIndicatorAnimation {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50px);
  }
}

.animateScrollIndicator {
  animation: scrollIndicatorAnimation 3s ease-in-out;
}

/* @media screen and (max-width: 768px) {
  .table {
    width: 100%;
    min-width: 100%;
  }

  .table thead {
    display: none; 
  }

  .table tr {
    display: block;
    margin-bottom: 10px;
    border-bottom: 2px solid #ddd;
  }

  .table td {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  }
  .table td .weatherSymbol {
    flex-direction: row;
    gap: 5rem;
  }

  .marginTop5 {
    justify-content: flex-start;
    gap: 2rem;
    width: 300px;
    display: flex;
  }
  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    padding-left: 10px;
    white-space: nowrap;
    font-weight: bold;
  }
} */
