.widgetContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid black; */
  /* cursor: pointer; */
  /* padding: 10px; */
  transition: background-color 0.5s ease; /* Smooth transition */
  font: 20px / 32px 'SourceSans';
}
.widgetContainer .header {
  margin: 0 !important;
  font: normal normal 34px/54px Weissenhof Grotesk;
  font-family: 'WeissenhofGrotesk';
  font-weight: 700;
}

/* .widgetContainer:hover {
  border: 1px solid #a92a4e;
} */

.widgetContainer .content {
  display: block;
  max-height: 14rem;
  overflow: auto;
  width: 100%;
  height: 100%;
  position: relative;
}
/* .widgetContainer .content .contentWarntext {
  padding: 2rem;
} */
.contentWarntext {
  padding: 1.5rem;
  /* width: 80%; */
}

.parentWarnwidgetStuff .pTagText {
  text-align: left;
  font: normal normal normal 20px/32px 'SourceSans';
  letter-spacing: 0px;
  color: #052e37;
  margin: 0 !important;
  padding: 0 !important;
  text-wrap: pretty;
}
.parentWarnwidgetStuff .pTagMarginTop {
  margin-top: 28px !important;
}
.parentWarnwidgetStuff .pTagMarginTop span {
  color: #052e37 !important;
  text-decoration: underline;
  cursor: pointer;
}

.yellow_light {
  background-color: #ffdb231a;
}

.orange_light {
  background-color: #ff95001a;
}

.red_light {
  background-color: #ff01001a;
}

.lightgray {
  background-color: #f3f5f5;
}
.yellow {
  background-color: #ffdb23;
}

.orange {
  background-color: #ff9500;
}

.red {
  background-color: #ff0100;
}

.warn {
  background-color: #a92a4e;
}
.warnlight {
  background-color: #a92a4e33;
}

.divButton {
  /* height: 10px; */
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  /*position: -webkit-sticky;*/ /* For Safari */
  right: 0;
  bottom: 0px;
}
.warnFlexHeaderandWarning {
  display: flex;
  align-items: center;
}

.warnFlexP {
  display: flex;
}
.icon {
  height: 100%;
  display: flex;
  align-items: center;
  /* // marginRight: '10px'; // Adjust the margin as needed */
  width: 100px;
  justify-content: center;
}

@media only screen and (max-width: 1500px) {
  .pTagMarginTop {
    display: none;
  }
  /* .warnFlexP {
    display: block;
  } */
}
@media only screen and (max-width: 1073px) {
  .pTagMarginTop {
    display: block;
  }
  .contentWarntext {
    /* padding: 0.5rem; */
    padding: 0 1.1rem;
    /* width: 80%; */
  }
  /* .warnFlexP {
    display: block;
  } */
}
@media only screen and (max-width: 500px) {
  .pTagMarginTop {
    display: none;
  }
  .contentWarntext {
    /* padding: 0.5rem; */
    padding: 0 0.7rem;
    /* width: 80%; */
  }
  .icon {
    width: 50px;
  }
  /* .warnFlexP {
    display: block;
  } */
}
