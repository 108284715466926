.card-section-block {
    @include layout-container-width();
    @include adjustMarginsToContainer($layout-container-width);

    @media(max-width: 1200px) {
        .container {
            flex-direction: column;

            div.image {
                width: 100%;
                max-height: 400px;
                overflow: hidden;
                margin-left: 0;
            }

            .block.__link > .link.container {
                align-items: flex-start;
            }
        }
    }

    background-color: $lime; // default if no theme selected

    &.theme-midnightGreen {
        background-color: $midnight-green;

        .container .link-listing .link button div svg g > * {
            fill: $lime!important;
        }

        .content {
            h3, h5 {
                color: $lime!important;
            }
            .info > div {
                color: $white!important;
            }
            .description {
                color: $white!important;
            }
            .link {
                color: $white!important;
            }
        }
    }
    &.theme-gray {
        background-color: $midnight-green-5;
    }
    &.theme-lime {
        background-color: $lime;
    }

    .container {
        display: flex;

        .content {
            padding: 60px 80px;
            width: 100%;

            .info {
                margin-bottom: 40px;

                div {
                    @include body-text-styles;
                    line-height: 32px;
                }
            }

            > .block.__link {
                .container {
                    margin-left: 0;
                }
                > .link > a {
                    flex-grow: 1;
                }
            }
        }

        .link-listing {
            .listings {
                display: flex;
                flex-wrap:wrap;
                flex-direction: row;
                justify-content: space-between;
                gap: 40px;

                .listing-block {
                    width: 30%;
                    flex-basis: 150px;
                    flex-grow: 1;
                    flex-shrink: 0;

                    .image-icon img {
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                    }
                }
            }
            span.description, .link a {
                @include body-text-styles;
                line-height: 32px;
            }
            .link a {
                font-weight: bold;
                padding-left:10px;

                &::after {
                    content: none;
                }
            }
            .link.container {
                margin-top: 30px;

                a {
                    padding: 0!important;
                }
            }
            .link {
                &:hover {
                    background: none!important;
                    text-decoration: underline;
                }
                button {
                    margin: 0!important;
                    border: none!important;
                    padding: 0!important;
                    display:flex!important;
                    flex-direction: row-reverse!important;
                    gap: 10px;

                    span {
                        max-width: unset!important;
                        font-weight: bold;
                    }

                    div {
                        flex-shrink:0;
                        line-height:32px;
                        width: unset!important;
                        height: unset!important;
                        position: static!important;
                        background: none!important;

                        svg {
                            transform: none!important;

                            g > * {
                                fill: $midnight-green!important;
                            }
                        }
                    }
                }
            }
        }
    }

    .image {
        flex-basis: 500px;
        height: auto;
        flex-shrink: 0;
        position: relative;
        display: flex;
        flex-direction: column;

        &::after {
            content: '';
            display: block;
            position: absolute;
            visibility: hidden;
            left: 0;
            top: 0;
            height: 100%;
            width: 1px;
        }

        .image-border {
            width: 10px;
            height: 100%;
            position: absolute;
            background-color: #BFCE40;
        }
    }
    .image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-left: 10px;
        flex-basis: 0px;
        flex-grow: 1;
        overflow-y: hidden;
    }

    // Link listing variation
    .link-listing {
        display: flex;
        flex-wrap: wrap;

        .listing-link {
            flex-shrink:0;
            flex-basis: 33%;

            .__link, .__link .link {
                margin: 0;
                padding: 0;

                span {
                    font-weight: normal;
                }
            }
        }
    }
}

@media(max-width: $largest-mobile-screen) {
    #page-document .blocks-group-wrapper > .block.card-section-block {
        &.mobile-margin-false {
            max-width: 100%;
            margin-left: 0!important;
            margin-right: 0!important;
        }

        .container .content {
            padding-left: 40px;
            padding-right: 40px;
        }

        .mobile-hidden {
            display: none;

            + img {
                margin-left: 0;
            }
        }

        .link-listing {
            flex-direction: column;
        }
    }
}
