/* p {
  margin: 0;
} */
.parentSearchContainer {
  position: relative;
  font: normal normal normal 20px/32px 'SourceSans';
  width: 360px;
  height: 60px;
}

.parentSearchContainer input {
  font: normal normal normal 20px / 32px 'SourceSans';
}

::placeholder {
  color: #052e37;
  opacity: 1; /* Firefox */
}

.searchContainer {
  position: absolute;
  /* top: 0; 
  left: 0;  */
  display: grid;
  grid-template-rows: auto 1fr;

  align-items: center;
  /* gap: 0.5rem; */
  /* margin: 1rem; */
  /* position: relative; */

  cursor: pointer;
}
.searchContainer_bar_gps {
  /* top: 0; 
  left: 0;  */
  display: flex;

  align-items: center;
  gap: 0.5rem;
  /* margin: 1rem; */
  position: relative;

  cursor: pointer;
}

.searchInput {
  display: flex;
  align-items: center;
  border: 1px solid #9babaf;
  border-radius: 5px;
  height: 60px;
  padding-top: 17px;
  padding-bottom: 18px;
  padding-left: 20px;
  padding-right: 20px;
  /* transition: border 0.3s; */
  /* width: 3; */

  /* margin-bottom: 1rem; */
}
/* .searchInput:hover {
  border: 3px solid #052e37;
} */
.searchInput:focus-within {
  border: 3px solid #052e37;
}
.searchResults ul {
  list-style: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.searchResults li {
  /* padding: 0.3rem;  */
  cursor: pointer;
  background-color: #e6eaeb;
  border-bottom: #9babaf 1px solid;
}

.searchResults li:hover {
  background-color: #ffff; /* Change background color on hover for better feedback */
}

.searchInput input {
  flex: 1;
  margin-left: 0.5rem;
  padding: 0.8rem 0.2rem;

  /* padding: 0.3rem; */
  /* margin-right: 0.5rem; */
  border: none;
  cursor: pointer;
  width: 280px;
}
.searchInput input:focus {
  border: none;
  outline: none;
  padding: 1px;
}
.searchResults {
  border: 1px solid #ccc;
  /* padding: 0.5rem; */
  /* margin-top: 12rem; */
  max-height: 200px;
  overflow-y: auto;
  /* position: absolute; */
  background-color: #e6eaeb;
  color: #052e37;
  /* border: 1px solid black; */
  z-index: 12;
  width: 100%;
  /* outline: 1px solid #007bff; */
}
.searchResults p {
  padding-left: 20px !important;
  padding-top: 12.5px !important;
  padding-bottom: 12.5px !important;
}

.searchResults ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.svgIconPositionBottom {
  display: flex;
  align-items: baseline;
}

@media (max-width: 1100px) {
  .parentSearchContainer {
    width: 100%;
  }
  .searchContainer {
    width: 100%;
  }
  .searchInput {
    width: 100%;
  }
  /* input {
    width: 100%;
  } */
  .parentSearchContainer input {
    width: 100%;
    /* font: normal normal normal 40px / 32px 'SourceSans';; */
  }
}
