
// image/video captions
figcaption {
    margin: 20px 0 0 0;
}
figcaption .description a, figcaption .description p {
    color: $midnight-green;
    font-size: 16px;
}
figcaption .title {
    display: none;
}
