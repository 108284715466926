.newsitem-view .block.__button .button.container.news-overview-link {
    margin-bottom: 0;
    margin-top: 0;

    a > button.ui.button {
        white-space: nowrap;
    }
}
.newsitem-view .block.__button:has(.button.container.news-overview-link) {
    margin-bottom: 0;
}
@container(max-width: 1000px) {
    .news-overview-link {
        margin-right: 40px!important;
        margin-left: 40px!important;
    }
}
.newsitem-view .top-overview-button {
    max-width: var(--default-container-width);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: $block-vertical-space;
    margin-bottom: 20px;
}
.newsitem-view .news-info {
    font-size: 16px;

    time:has(+ span) {
        border-right: 2px solid $lime;
        padding-right: 10px;
        margin-right: 10px;
    }
}
.newsitem-view h1.documentFirstHeading {
    margin-top: 0!important;
}
