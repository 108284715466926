/////////////////////////////
/* scss variable overrides */
/////////////////////////////


// All variables has to be `!default`'ed in order to be able to
// be overriden by add-ons
@use 'sass:map';

// Volto Light Overrides
$layout-container-width: 1600px;
$default-container-width: 960px;
$horizontal-space-small-screens: 40px;
$page-font: 'SourceSans';
$header-font: 'WeissenhofGrotesk';
$computer-breakpoint: 1200px;
$block-vertical-space: 60px;

@import '../../node_modules/@kitconcept/volto-light-theme/src/theme/main.scss';