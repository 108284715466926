// Source Sans
@font-face {
    font-family: 'SourceSans';
    src: url('./fonts/Source-Sans-Pro/source-sans-pro-v21-latin-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SourceSans';
    src: url('./fonts/Source-Sans-Pro/source-sans-pro-v21-latin-700.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'SourceSans';
    src: url('./fonts/Source-Sans-Pro/source-sans-pro-v21-latin-italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'SourceSans';
    src: url('./fonts/Source-Sans-Pro/source-sans-pro-v21-latin-700italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}
// Weissenhof Grotesk
@font-face {
    font-family: 'WeissenhofGrotesk';
    src: url('./fonts/Weissenhof-Grotesk/WeissenhofGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'WeissenhofGrotesk';
    src: url('./fonts/Weissenhof-Grotesk/WeissenhofGrotesk-Medium.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
