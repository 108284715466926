$midnight-green: #052E37;
$midnight-green-muted: #052E37CC;
$midnight-green-30: #9BABAF;
$midnight-green-10: #E6EAEB;
$midnight-green-5: #F3F5F5;

$lime: #BFCE40;
$lime-20: #F2F5D9;

$red: #A92A4E;
$red-20: #A92A4E33;
$red-10: #FAE5EB;