.maplibregl-popup-content {
  background-color: #052e37 !important;
  color: #fff;
  width: 300px;
  min-height: 148px;
  border-radius: unset !important;
}

.maplibregl-popup-close-button {
  color: #fff;
}
/* not sure why it was implemented in the first version */
/* .maplibregl-popup {
  z-index: 3;
} */
@media (max-width: 800px) {
  .maplibregl-popup-content {
    display: none;
  }
}
