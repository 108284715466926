.block.slider {
    max-width: $default-container-width!important;

    &:has(.image-carousel-item) {
        .slider-wrapper {
            padding-bottom: 40px;
            background: $midnight-green-5;
            border: 2px solid $midnight-green-10;
        }
        .highlight-image-wrapper img {
            background-color: #fff;
        }

        .teaser-item-title {
            position: relative;
            top: 0;
            height: auto;
            min-height: 0;
            width: auto;
            transform: none;
        }

        .slider-button {
            opacity: 1;
            position: absolute;
            height: min-content;
            bottom: 30px;
            background: none;

            svg {
                height: 16px!important;
            }
            svg * {
                fill: $midnight-green;
            }
        }

        .image-carousel-item p {
            padding: 30px 60px;
            font-size: 16px;
            line-height: 23px;
        }

        .slider-dots {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 30px;
            padding: 0;

            .slider-dot {
                width: 20px;
                margin: 0;

                &::after {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 1px solid #000;
                }
            }
        }
    }
}