.infoContainer {
  display: flex;
  align-items: center;
  grid-row: 1 !important;
  margin-bottom: 1rem;
  font: normal normal normal 20px/32px 'SourceSans';
}
.infoContainer a {
  cursor: pointer;
  color: #052e37;
  text-decoration: underline;
}
.infoContainer a:hover {
  color: inherit;
  text-decoration: underline;
}
.infoButton {
  background-color: none;
  /* color: white; */
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
}
button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}
/* .infoButton:hover {
  background-color: #0056b3;
} */
/* 
.infoText {
  font-size: 16px;
} */
