.pareantChart {
  /* padding-top: 5rem; */
  display: grid;
  grid-row: 2;
  /* gap: 1rem; */
  grid-column: 1;
}

.nominatimPosition {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: right;
  gap: 50px;
}

.widgetsOrder {
  display: flex;
  gap: 1rem;
  margin: 40px 0 80px 0;
}

.highcharts-tooltip-container {
  background-color: #052e37;
  font: normal normal normal 19px/30px 'SourceSans';
  width: 300px;
  height: 92px;
}
/* .highcharts-root {
  width: 300px;
} */
.highcharts-label {
  font: normal normal normal 19px/30px 'SourceSans';
  width: 300px;
  height: 92px;
}

/* sind wind geschwindigkeiten! */
.highcharts-data-label-color-1 text {
  font: normal normal normal 19px/30px 'SourceSans';
  font-weight: lighter !important;
}

.weatherWidget {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  min-height: 14rem;
  flex: 1;
  /* max-width: 50%; */
  max-width: 62.5%;
}
.weatherWidgetFlex5 {
  flex: 5;
}
.weatherWidgetFlex3 {
  flex: 3;
}
.highchartsParent {
  grid-row: 3;
  grid-column: 1;
  width: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  /* gap: 1rem; */
  flex-wrap: wrap;
  flex-direction: column;
}
.highchartsParent p {
  /* text-align: left; */
  font: normal normal normal 34px/48px 'WeissenhofGrotesk';
  font-weight: 600;
  letter-spacing: 0px;
  color: #052e37;
  margin: unset !important;
}
.highchartsChild1 {
  position: relative;
  /* max-width: calc(100% - 31rem); */
  width: 100%;
  flex-shrink: 1;
  margin: 36px 0 80px 0;
}
.highchartsChild {
  max-width: 2500px;
  min-width: 700px;
  /* height: 400px; */
  width: 100%;
  /* height: 500px; */
  flex-grow: 1;
  z-index: 1;
  /* display: flex; */
}

.highchartsChild2 {
  font-size: 1.1rem;
  /* width: 100%; */
  /* width: 30rem; */
  background-color: #263645;
  background-color: var(--wb-ds-color-secondary--2x-dark);
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* flex-basis: 100%; */
  order: 1;
  /* max-width: 100%; */
  box-sizing: border-box;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.25);
  box-shadow: var(--wb-ds-box-shadow--x-hard);
  order: 3;
  /* flex-basis: 10%; */
  /* max-width: calc(29.4rem - 4px); */
  flex: 1;
  max-width: 100%;
  width: auto;
  overflow-x: auto;
  border-bottom: 1px solid black;
}

/* highcharts setting: */
#y-axis-container {
  position: sticky;
  left: 0;
  top: 0;
  width: 50px; /* Set a fixed width for the Y-axis container */
  height: 100px;
  background-color: #f4f4f4;
  z-index: 2; /* Place it above the chart */
}

#y-axis-labels {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  text-anchor: start;
  transform: translate(0, 0);
  opacity: 1;
}

.placeholderChart {
  height: 36em;
}

/* background for highcharts title temperatur */
.y-axis-title-background {
  color: #fff;
  /* background-color: #a92a4e; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px; /* Adjust padding as needed */
}
.y-axis2-title-background {
  color: #fff;
  /* background-color: #2a47a9; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px; /* Adjust padding as needed */
}

/* wird das gebrauct */
@media only screen and (max-width: 1279px) {
  /* .highchartsParent {
    flex-direction: column-reverse;
  } */
  .highchartsChild1 {
    max-width: 100%;
  }
  .highchartsChild1 > .highchartsChild {
    max-width: 100%;
    width: 100%;
    min-width: 300px;
  }
}

@media only screen and (max-width: 1072px) {
  .widgetsOrder {
    /* flex-direction: column; */
    flex-direction: column-reverse;
    display: flex;
    width: 100%;
  }
  .weatherWidget {
    max-width: 100%;
  }
  .nominatimPosition {
    flex-direction: column-reverse;
    gap: 1rem;
    align-items: flex-end;
  }
}
