/* just for visualition on hover */
.weather_forecast {
  z-index: 0;
}
.weather_forecast:hover {
  z-index: 100;
}

.aClass {
  text-decoration: none;
}

.label_weatherforecast_5days {
  cursor: pointer;
  font-size: 1rem;
  font-weight: normal;
  display: flex;
}
.label_weatherforecast_5days .svg_icon {
  z-index: 2;
  /* margin-top: -30px;
  margin-right: -2px; */
  margin-top: -27px;
  margin-right: -5px;
}

.label_weatherforecast_5days .label_weatherforecast_5days_header {
  display: flex;
  /* flex-direction: column; */
  /* gap: 5px; */
  flex-direction: row;
  color: white;
  /* background-color: #37585f; */
  z-index: 1;
  /* min-width: 85px; */
  width: auto;
  height: 46px;
  justify-content: space-between;
  margin-left: -10px;
  /* border: 1px solid #0000004f; */
}

.label_weatherforecast_5days
  .label_weatherforecast_5days_header:hover
  .label_weatherforecast_5days_headerTitle {
  /* background-color: rgba(103, 168, 203, 0.875); */
  background-color: #fff;
  /* font-weight: bold; */
  color: #052e37;
  /* border: 1px solid #0000004f; */
}

.label_weatherforecast_5days_header .label_weatherforecast_5days_headerTitle {
  /* margin-left: 0.5rem; */
  padding: 13px;
  /* border-right: 1px solid white; */
  display: flex;
  background-color: #052e37;
  font: normal normal bold 16px/32px 'SourceSans';
  opacity: 0.85;
  align-items: center;
  font-weight: bold;
}
.label_weatherforecast_5days
  .label_weatherforecast_5days_headerTitleWithWarningFlex {
  display: flex;
  flex-direction: row;
}

.label_weatherforecast_5days .label_weatherforecast_5days_headerTempHeader {
  display: flex;
  background-color: #052e37;
  flex-direction: column;

  /* gap: 5px; */
  justify-content: center;

  align-items: center;
  border-left: solid 1px white;
  height: 46px;
  width: 40px;
  font: normal normal normal 16px/32px 'SourceSans';
}
.label_weatherforecast_5days .yellow {
  background-color: #ffdb23 !important;
}

.label_weatherforecast_5days .orange {
  background-color: #ff9500 !important;
}

.label_weatherforecast_5days .red {
  background-color: #ff0100 !important;
}
/* .label_weatherforecast_5days
  .label_weatherforecast_5days_headerTempHeaderWarning {
  background-color: #a92a4e;
} */
.label_weatherforecast_5days
  .label_weatherforecast_5days_headerTempHeaderWarning
  svg {
  filter: invert(1);
}
/* 
.label_weatherforecast_5days
  .label_weatherforecast_5days_header:hover
  .label_weatherforecast_5days_headerTempHeader {
  background-color: white;
  border-color: black;
} */

.label_weatherforecast_5days
  .label_weatherforecast_5days_headerTempHeader
  .label_weatherforecast_5days_headerTempHeaderTemp {
  margin: 0;
  width: 22px;
  /* background-color: #427a97f0; */
  font-weight: normal;
  text-align: center;
  line-height: 22px;
}

.label_weatherforecast_5days .label_weatherforecast_5days_header:hover {
  background-color: #fff;
  color: black;
}
.label_weatherforecast_5days
  .label_weatherforecast_5days_header:hover
  .label_weatherforecast_5days_headerTempHeader {
  background-color: white;
  border-color: #052e37;
  color: #052e37;
}

.label_weatherforecast_5days .label_weatherforecast_5days_header:hover svg {
  filter: invert(1);
  fill: #052e37 !important;
}
