.weatherWidgetMain {
  display: flex;
  align-items: center;
  /* border: 1px solid; */
  gap: 30px;
  justify-content: center;
  color: #052e37;
  background-color: #f3f5f5;
  font: normal normal normal 20px/30px 'SourceSans';
}

/* .title {
  font: normal normal 34px/54px Weissenhof Grotesk;
} */
.title p {
  margin: 0 !important;
  font: normal normal 34px/54px Weissenhof Grotesk;
  font-family: 'WeissenhofGrotesk';
  font-weight: bold;
}

.weatherWidgetMain .widgetTempSlahRain {
  display: flex;
  flex-wrap: wrap;
  /* font: normal normal medium 26px/41px Weissenhof Grotesk; */
  /* font: normal normal 26px/41px 'SourceSans';; */
  font: 26px/41px Weissenhof Grotesk;
  align-items: center;
  font-weight: bold;
  font-family: 'WeissenhofGrotesk';
}
.weatherWidgetMain .slash {
  margin-left: 1rem;

  margin-right: 1rem;
  color: #bfce40;
}
.weatherWidgetMain .iconMargin {
  margin-right: 0.5rem;
}
.weatherText {
  font: 26px/41px Weissenhof Grotesk;
  align-items: center;
  font-weight: bold;
  font-family: 'WeissenhofGrotesk';
}

@media only screen and (max-width: 1072px) {
  .weatherWidgetMain {
    justify-content: space-evenly;
  }
  /* .warnFlexP {
    display: flex;
  } */
}
@media only screen and (max-width: 600px) {
  .weatherWidgetMain .slash {
    margin: 0 0.5rem;
  }
}
