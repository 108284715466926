.accordion-weatherforecast-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 121px; /* for desktop mode*/
  color: #052e37;
  font: normal normal normal 20px/32px 'SourceSans';
}

.accordion-weatherforecast-column {
  display: flex;
  flex-direction: column;
}
/* .accordion-weatherforecast-container .marginTop {
  margin-top: 5rem;
} */
.accordion-weatherforecast-item {
  border-bottom: 1px solid #9babaf;
  /* border-radius: 4px; */
  /* margin-bottom: 0.5rem; */
}
.accordion-weatherforecast-item:first {
  border-top: 1px solid #9babaf;
}

.accordion-weatherforecast-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  /* background-color: #f7f7f7; */
}
.accordion-weatherforecast-header-first {
  padding: 0 0rem 0.5rem 0;
}

.accordion-weatherforecast-title {
  /* font-size: 16px; */

  font: normal normal normal 20px/32px 'SourceSans';
}

.accordion-weatherforecast-arrow {
  font-size: 18px;
}

.accordion-weatherforecast-content {
  background-color: #fff;
  /* opacity: 0; */
  /* height: auto; */
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.accordion-weatherforecast-content.active {
  max-height: 700px; /* A large value to ensure content fits */
  opacity: 1;
  padding-bottom: 40px;
  /* padding: 10px; */
  /* opacity: 1; */
}

.always-visible .accordion-weatherforecast-header {
  cursor: default;
}

/* .always-visible .accordion-weatherforecast-header:hover {
  background-color: #f7f7f7;
} */

@media (max-width: 940px) {
  .accordion-weatherforecast-container {
    grid-template-columns: none;
    gap: 60px;
  }
  .accordion-weatherforecast-containerNoGapMobile {
    gap: unset;
  }
}
