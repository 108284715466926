#page-document .blocks-group-wrapper .block.searchkitsearch {
    @include default-container-width();
    @include adjustMarginsToContainer($default-container-width);

    .kitsearch-header-wrapper {
        display: flex;
        gap: 20px 40px;
        flex-wrap: wrap;

        > * {
            flex-basis: calc(50% - 24px);
            flex-grow: 1;

            @media(min-width: 670px) {
                min-width: 400px;
            }
        }
    }

    .ui.container {
        margin: 0!important;
    }

    > .ui.segment {
        padding-top: 0!important;

        .grid {
            padding: 0!important;
        }
    }

    div.countlabel {
        @include h4-styles();
    }
    div.meta {
        display: none;
    }
    a.header {
        @include h4-styles();
        text-decoration: underline;
    }

    div.description {
        margin-top: 7px;
    }

    div.description a {
        @include text-styles();
        color: $midnight-green;
        cursor: default;
        pointer-events: none;
        line-height: 32px;
    }

    div.extra.metadata {
        margin-top: 20px;
    }
    div.extra.metadata span {
        font-size: 16px;
        line-height: 23px;
    }

    .fnresults .ui.items > .item button {
        margin: 0.25rem 0.5rem 0.25rem 0em;
        font-family: 'SourceSans';
        font-size: 16px;
        color: $midnight-green;
        font-weight: normal;
    }


    .ui.items > .item > .content {
        cursor: default;
    }
    .ui.items > .item > .content > .description {
        margin-top: 0;
        line-height: 32px;
        color: $midnight-green;
    }

    span.metadataseparator {
        color: $lime;
    }

    div.item.searchkitresultitem {
        border-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: $block-vertical-space;
    }

    div.sortby {
        display: none;
    }

    .searchbar-wrapper {
        > div.action.input {
            width: 100%!important;
            flex-wrap: wrap;
            gap: 20px;
        }
        > div > input {
            border-radius: 5px!important;
            @include text-styles();
            padding-left: 20px!important;
            padding-right: 20px!important;
        }
        .ui.action.input > button.ui.button {
            display: block!important;
            border-radius: 0!important;
            @include button-styles();
            text-transform: capitalize;
        }
        i.delete.icon {
            display: none;
        }
        > div > i.search.icon {
            display: none;
        }
    }

    .fnresults {
        margin-top: 100px;

        .countlabel {
            margin-bottom: 80px;
        }
    }

    .bucketaggregations {
        width: 100%;

        .bucketAE .ui.dropdown.fnfilter {
            @include text-styles();
            font-size: 20px!important;

            .dropdown.icon {
                font-size: 35px!important;
            }
        }

        .ui.multiple.dropdown > .text {
            position: unset;
            padding: 0;
            margin: 0;
            line-height: 45px;
        }

        .bucketAE {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          height:100%;

          .ui.dropdown .menu > .item:hover {
            background-color: $white!important;
          }

          > div {
            height:100%;
          }
          .ui.dropdown.fnfilter {
            display: flex;
            min-height: 3rem;
            align-items: center;
            justify-content: space-between;
            // padding: 0 0 0 .3rem;
            padding: 0.7rem 1rem 0.4rem 1rem;
            margin: 0;
            background-color: $midnight-green-10;
            font-size: 1rem;
  
            & > .dropdown.icon {
              font-size: 1rem;
            }
  
            &.selected {
              background-color: $midnight-green-10;
              color: $midnight-green;
  
              .text {
                max-width: 95%;
              }
            }
  
            .menu {
              width: 100%;
              border: none;
              background-color: $midnight-green-10;
              box-shadow: none;

              &.visible {
                box-shadow: 2px 2px 3px 0px $midnight-green-10;
              }
  
              & > .item {
                line-height: $block-vertical-space!important;
                padding: 0!important;
                padding-left: 1rem!important;
                padding-right: 1rem!important;
                border-top: 1px solid $midnight-green-30;
                @include text-styles();

                & .item.isSelected {
                  font-weight: bold;
  
                  svg {
                    stroke: currentColor;
                    stroke-width: 3px;
                  }
                }
              }
            }
  
            div.visible .menu {
              display: block !important;
              visibility: visible !important;
            }
  
            // Hack for dropdown with scrollbar
            &.scrolloptions {
              .menu > .item {
                padding-right: 0.9rem !important;
              }
            }
          }
  
          .deleteFilter {
            display: flex;
            width: 3rem;
            min-width: 3rem;
            height: 3rem;
            align-items: center;
            justify-content: center;
            margin: 0;
            background-color: $midnight-green-10;
            color: #fff;
            cursor: pointer;
            font-size: 1.2rem;
            font-weight: 700;
  
            &.unselected {
              opacity: 0;
            }
          }
        }
      }

      .ui.segment {
        box-shadow: none;
        border: none;
        margin: 0;
        padding: 0;
        margin-top: $block-vertical-space;

        > .header {
            @include text-styles();
            margin-bottom: $block-vertical-space;
        }
        >.button {
            @include button-styles();
            border-radius: 0;
        }
      }
}