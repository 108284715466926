/* For Highcharts ! */

.textAlignRight {
  text-align: right;
}
.custom-label {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.highcharts-tooltip-container {
  background-color: #052e37;
  font: normal normal normal 19px/30px 'SourceSans';
  width: 300px;
  min-height: 92px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  z-index: 11;
}
/* .highcharts-root {
  width: 300px;
} */
.highcharts-label {
  font: normal normal normal 19px/30px 'SourceSans';
  width: 300px;
  height: 92px;
}
.highcharts-label text {
  font: normal normal normal 19px/30px 'SourceSans';
  width: 300px;
  height: 92px;
}

/* sind wind geschwindigkeiten! */
.highcharts-data-label-color-1 text {
  font: normal normal normal 19px/30px 'SourceSans';
  font-weight: lighter !important;
}

.y-axis-title-background-Tawes span {
  color: #fff;
  background-color: #2a47a9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px; /* Adjust padding as needed */
  width: 477px;
}
