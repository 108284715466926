div.warning {
    padding: 60px 0;
    background-color: $midnight-green-5;

    &.fatal {
        background-color: red;

        .warning-content {
            h1 {
                color: $white!important;
            }
            p.documentDescription {
                color: $white!important;
            }
            button.link, .block.__link .container .button.link {
                border-color: $white;
                color: $white;

                &:hover {
                    background: transparent!important;
                }

                > div {
                    background-color: $white!important;

                    svg g > * {
                        fill: $red!important;
                    }
                }
            }
        }

        .close {
            svg g > * {
                fill: $white!important;
            }
        }
    }

    &.closed, &.hidden {
        display: none;
    }

    @container(max-width: 1640px) {
        .warning-wrapper {
            margin-right: 20px!important;
            margin-left: 20px!important;
        }
    }

    .warning-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 60px;
        max-width: var(--layout-container-width);
        margin-left: auto;
        margin-right: auto;
        position: relative;

        .warning-content {
            flex-grow: 1;

            .blocks-group-wrapper {
                margin: 0!important;
                padding: 0!important;
            }
            h1 {
                padding-top: 0!important;
                margin-top: 0!important;
                margin-bottom: 10px;
                font-size: 56px!important;
                line-height: 52px!important;
                font-family:'WeissenhofGrotesk'!important;
                font-weight: bold;
                // color: $white!important;
            }
            p.documentDescription {
                @include body-text-styles;
                // color: $white!important;
                margin-bottom: 20px;
            }

            .block.__link .link.container {
                margin: 0!important;
            }

            // button.link, .block.__link .container .button.link {
            //     border-color: $white;
            //     color: $white;

            //     &:hover {
            //         background: transparent!important;
            //     }

            //     > div {
            //         background-color: $white!important;

            //         svg g > * {
            //             fill: $red!important;
            //         }
            //     }
            // }
        }

        .warning-image img {
            width: 160px;
            height: 160px;
        }

        .close {
            margin-left: auto;
            position: absolute;
            right: 0;

            svg g > * {
                fill: $midnight-green;
            }
        }
    }
}

@media(max-width: $computer-breakpoint) {
    div.warning {
        padding: 30px 0;

        .warning-wrapper {
            flex-wrap: wrap!important;
            gap: 30px!important;
    
            .warning-image img {
                width: 40px!important;
                height: 40px!important;
            }
    
            h1.documentFirstHeading {
                font-size: 40px !important;
                line-height: 52px !important;
            }
        }
    }
}
