@import '../../node_modules/@kitconcept/volto-light-theme/src/theme/layout.scss';

#page-document .blocks-group-wrapper {
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6,
    & > ul,
    & > ol,
    & > li,
    & > p,
    & > span,
    & > blockquote,
    & > .block.image.align.left.medium,
    & > .block.image.align.left.small,
    & > .block.image.align.right.medium,
    & > .block.video.align.left,
    & > .block.video.align.right,
    & > .block.maps.align.left,
    & > .block.maps.align.right,
    & > .block.image.align.right.small,
    & > .block.video.align.center .video-inner,
    & > .block.maps.align.center .maps-inner,
    .block.image.align.center figure,
    .event-details,
    .download-event,
    .block.separator.has--align--left .line,
    .block.separator.has--align--center .line,
    & > pre,
    & > .block.code,
    & > .block.infoBox,
    & > .block.slider,
    & > .block.slider,
    & > .block.event_listing,
    & > .block.link_listing,
    & > .block.teaser-listing:not(.two-columns),
    & > .block.news-listing:not(.two-columns) {
        @include default-container-width();
        @include adjustMarginsToContainer($default-container-width);
    }
}

#page-document .blocks-group-wrapper > * {
    &.block.wide {
        max-width: var(--layout-container-width);
        margin-right: auto;
        margin-left: auto;

        > .grid {
            max-width: unset!important;
        }

        // Grids adjustments (has to be paired with collections/grid.variables)
        // TODO: Move to our own grid component
        .block.__grid .ui.stackable,
        .block.__grid h2.headline,
        .block.gridBlock .ui.stackable,
        .block.gridBlock .grid-items,
        .block.gridBlock h2.headline {
            @include layout-container-width();
            // Adding 2 * 10px (one for each side) we force the container to extend the value of
            // the gutter to match the other blocks margin.
            max-width: calc(var(--layout-container-width) + 2 * 10px);
        }
    }
}

#page-document .blocks-group-wrapper {
    & > .block.card-section-block {
        @include layout-container-width();
        @include adjustMarginsToContainer($layout-container-width);
    }
}

body.contenttype-lrf {
    #page-document .blocks-group-wrapper > h1.documentFirstHeading {
        @include layout-container-width();
        @include adjustMarginsToContainer($layout-container-width);
    }
}
