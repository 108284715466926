// mixins
@mixin five-columns {
    .searchBlock-facets > .row:has(.search-input-resultscount-sort) > .column {
        .search-input-resultscount-sort,
        .facets div.grid > div {
            width: calc(20% - 30px)!important;

            &:has(.daterange-facet) {
                width: calc(40% - 30px)!important;
            }
        }
    }
}
@mixin four-columns {
    .searchBlock-facets > .row:has(.search-input-resultscount-sort) > .column {
        .search-input-resultscount-sort,
        .facets div.grid > div {
            width: calc(25% - 30px)!important;

            &:has(.daterange-facet) {
                width: calc(50% - 30px)!important;
            }
        }
    }
}
@mixin two-columns {
    .searchBlock-facets > .row:has(.search-input-resultscount-sort) > .column {
        .search-input-resultscount-sort,
        .facets div.grid > div {
            width: calc(50% - 20px)!important;

            &:has(.daterange-facet) {
                width: 100%!important;
            }
        }
    }
}

// employee listing
#page-document .blocks-group-wrapper > .block.employee-listing {
    @include default-container-width();
    @include adjustMarginsToContainer($default-container-width);

    @container (min-width: #{$largest-mobile-screen}) {
        @include two-columns();
    }

    .accordion-block {
        margin-bottom: $block-vertical-space;

        > .ui.styled.accordion {
            padding: 0;

            > .title.accordion-title > span {
                font-size: 26px;

                @include h4-styles;
            }

            // second level
            .ui.styled.accordion {
                padding-left: 20px;
                padding-right: 0;

                &:last-child {
                    border-bottom: none;
                }

                .title.accordion-title {
                    padding-left: 0;

                    > span {
                        font-size: 20px;
                    }
                    svg.icon {
                        height: 16px!important;
                    }
                }
            }
        }

        span {
            color: $midnight-green!important;
        }

        div.active svg {
            * {
                fill: $midnight-green-30;
            }
        }

        > div > div {
            > span {
                font-size: 20px;
            }
            > svg {
                height: 16px!important;
                transform: none;
            }
        }

        .blocks-group-wrapper {
            padding-bottom: 40px!important;
            margin-bottom: 0!important;
        }
        .blocks-group-wrapper p {
            padding-left: 10px;
            padding-right: 10px;
        }

        .employee {
            display: flex;
            margin-bottom: 40px;

            @media screen and (max-width: $tablet-breakpoint) {
                .image-wrapper {
                    display: none;
                }
            }

            .image-wrapper {
                background-color: $midnight-green-5;
                width: 220px;
                height: 220px;
                flex-shrink: 0;

                img {
                    width: 220px;
                    height: 220px;
                    object-fit: cover;
                }
            }

            .employee-data {
                margin-left: 40px;
                font-size: 20px;
                margin-top: auto;
                margin-bottom: auto;

                h5 {
                    @include h2-styles;
                    margin-bottom: 8px!important;

                    span{
                        font-size: 26px;
                    }
                }

                ul {
                    list-style: none;
                    padding-left: 0;

                    li {
                        line-height: 30px;

                        &.links a {
                            color: $midnight-green;
                            text-decoration: underline;

                            &:hover {
                                text-decoration: none;
                            }
                        }

                        strong {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

.accordion-block .ui.styled.accordion .title.accordion-title,
.block-editor-accordion .ui.styled.accordion .title.accordion-title {
    padding: 16px 0;
}

.accordion-block .ui.styled.accordion,
.accordion-block .ui.styled.accordion .title.accordion-title,
.block-editor-accordion .ui.styled.accordion .title.accordion-title {
    border-color: $midnight-green-30!important;
    padding-left: 10px;
    padding-right: 10px;
}


// teaser listing
#page-document .blocks-group-wrapper > .block.teaser-listing,
#page-document .blocks-group-wrapper > .block.news-listing {
    max-width: var(--default-container-width);

    @container (min-width: #{$largest-mobile-screen}) {
        @include two-columns();
    }

    .teaser-listing-item {
        margin-bottom: $block-vertical-space;

        @media screen and (max-width: $tablet-breakpoint) {
            .teaser-item {
                flex-direction: column;
                height: unset;

                .content {
                    max-height: 240px;
                }
            }
        }

        .teaser-item {
            position: relative;

            .content {
                h2 {
                    margin-bottom: 20px;
                }
                p {
                    margin-bottom: 16px;
                }
            }

            .teaser-listing-info {
                position: absolute;
                bottom: 20px;
                font-size: 16px;
                padding-right: 70px;
                // fallback, if tags would get too long at any viewport.
                max-height: 50px;
                overflow: hidden;

                .date:has(+ span) {
                    padding-right: 10px;
                    margin-right: 10px;
                    border-right: 2px solid $lime;
                }
            }

            .arrow-link {
                right: 0!important;
            }
        }
    }
}

// project listing
#page-document .blocks-group-wrapper > .block.project-listing {
    max-width: var(--layout-container-width);

    // project listing - search
    .searchBlock-container {
        max-width: var(--layout-container-width);
    }

    @container (min-width: #{$computer-breakpoint}) {
        @include four-columns();
    }

    @container (min-width: #{$largest-mobile-screen}) and (max-width: #{$computer-breakpoint}) {
        @include two-columns();
    }

    @media screen and (max-width: $computer-breakpoint) {
        .items {
            gap: 40px 40px!important;

            .projects-listing {
                width: calc(50% - 20px)!important;
            }
        }
    }
    @media screen and (max-width: 950px) {
        .items {
            flex-direction: column;

            .projects-listing {
                width: 100%!important;
            }
        }
    }
}
// event listing
#page-document .blocks-group-wrapper > .block.event-listing {
    @include layout-container-width();
    @include adjustMarginsToContainer($layout-container-width);

    .searchBlock-facets .row:has(.facets) {
        margin-left: 20px;
        margin-right: 20px;
    }

    .searchBlock-container {
        max-width: var(--layout-container-width);
    }

    div.items {
        max-width: var(--default-container-width);
        margin-left: auto;
        margin-right: auto;
    }

    @container (min-width: #{$computer-breakpoint}) {
        @include five-columns();
    }

    @container (min-width: #{$largest-mobile-screen}) and (max-width: #{$computer-breakpoint}) {
        @include two-columns();
    }
}
// project listing, event listing
#page-document .blocks-group-wrapper > .block.event-listing,
#page-document .blocks-group-wrapper > .block.project-listing {

    .items:has(.projects-listing) {
        display: flex;
        flex-wrap: wrap;
        gap: 60px 80px;

        .projects-listing {
            width: calc(50% - 40px);
        }
    }

    a {
        color: $midnight-green;
    }

    .event-item {
        margin-bottom: $block-vertical-space;
    }
    .event-item,
    .project-item {
        height: 260px;
        background-color: $midnight-green-5;
        position: relative;
        padding: 40px 60px 60px 40px;

        &.internalEvent {
            background-color: $lime-20;
        }

        &:hover {
            div.arrow-link {
                background-color: $midnight-green;
            }
        }

        @media screen and (max-width: $tablet-breakpoint) {
            .event-item, .project-item {
                height: unset;
            }
        }

        .content {
            height: 100%;

            h2 {
                font-size: 26px;
                margin-bottom: 20px;
                text-wrap: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            p {
                margin-bottom: 16px;
                flex: 1;
                overflow: hidden;
                line-height: 30px;
            }
        }

        .event-listing-info, .project-listing-info {
            position: absolute;
            bottom: 20px;
            font-size: 16px;
            margin-right: 60px;
            // fallback, if tags would get too long at any viewport.
            max-height: 50px;
            overflow: hidden;

            .date, .time:has(+ .location), .location:has(+ .eventOrganizer) {
                padding-right: 10px;
                margin-right: 10px;
                border-right: 2px solid $lime;
            }
        }

        .eventOrganizer {
            font-weight: bold;
        }

        .arrow-link {
            width: 60px;
            height: 60px;
            background: #9BABAF;
            position: absolute;
            bottom: 0;
            right: 0;
            transition: background-color 200ms;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                * {
                    fill: $white;
                }
            }
        }
    }
}

// emulate block behavior
@container (max-width: 1600px) {
    .block.project-listing,
    #page-document .blocks-group-wrapper > .block.employee-listing:not(.search) {
        margin-right: 40px!important;
        margin-left: 40px!important;
    }
}
@container (max-width: 1000px) {
    // .event-item,
    // .teaser-item,
    #page-document .blocks-group-wrapper > .block.link-listing,
    #page-document .blocks-group-wrapper > .block.project-listing {
        margin-right: 20px;
        margin-left: 20px;
    }
    .external-partners {
        flex-wrap: wrap;

        .logo {
            width: calc(50% - 10px) !important;
        }
    }
}

// link listing
#page-document .blocks-group-wrapper > .block.listing.link-listing {
    max-width: $default-container-width;
}
.block.listing.link-listing,
.block.search.link-listing {
    .block.__link::before, .block.__link:hover::before {
        border: none;
    }
}
.block.listing.link-listing .pagination-wrapper-top,
.block.listing.link-listing .pagination-wrapper-bottom {
    max-width: $default-container-width;
    margin-left: auto;
    margin-right: auto;
}
.block.listing .pagination-wrapper-bottom .ui.pagination.menu,
.block.listing .pagination-wrapper-top .ui.pagination.menu,
.block.search .pagination-wrapper-bottom .ui.pagination.menu,
.block.search .pagination-wrapper-top .ui.pagination.menu,
.block.searchkitsearch .pagination-wrapper-bottom .ui.pagination.menu {
    display: flex;
    gap: 25px;

    .item {
        border: 0;
        width: 60px;
        height: 60px;
        padding: 10px!important;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 0!important;

        &[type=pageItem] {
            background-color: $midnight-green-30!important;
            color: $white!important;
            font-size: 22px;
        }

        &[type=ellipsisItem] {
            color: $midnight-green!important;
        }

        &.active {
            background-color: $midnight-green!important;
        }
    }
}

// pagination
.block.listing .pagination-wrapper-bottom,
.block.search .pagination-wrapper-bottom,
.block.searchkitsearch .pagination-wrapper-bottom {
    margin-top: $block-vertical-space;
}
.block.search .search-details {
    display: none;
}
.block.listing .pagination-wrapper-top,
.block.listing .pagination-wrapper-bottom,
.block.search .pagination-wrapper-top,
.block.search .pagination-wrapper-bottom,
.block.searchkitsearch .pagination-wrapper-bottom {
    margin-bottom: $block-vertical-space;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px 0;
    justify-content: center;

    .total {
        @include h4-styles;
    }
}
@container (min-width: 1000px) {
    .block.search, .block.listing, .block.searchkitsearch {
        .pagination-wrapper-top,
        .pagination-wrapper-bottom {
            .ui.pagination.mobile-pagination {
                display: none!important;
            }
        }
    }
}
@container (max-width: 1000px) {
    .block.search, .block.listing, .block.searchkitsearch {
        .pagination-wrapper-top,
        .pagination-wrapper-bottom {
            .ui.pagination.desktop-pagination {
                display: none!important;
            }
            .ui.pagination.mobile-pagination {
                gap: 10px;
                > a {
                    width: 50px!important;
                    height: 50px!important;
                }
            }
            .total {
                font-size: 20px;
            }
        }
    }
}
@container (max-width: 450px) {
    .block.search, .block.listing, .block.searchkitsearch {
        .pagination-wrapper-top,
        .pagination-wrapper-bottom {
            .ui.pagination.mobile-pagination {
                gap: 0px;

                > a {
                    width: 45px!important;
                    height: 45px!important;
                }
            }
        }
    }
}

#page-document .blocks-group-wrapper .block.listing.two-columns {
    @include layout-container-width();
    @include adjustMarginsToContainer($layout-container-width);

    .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 60px;

        .teaser-listing-item {
            flex-basis: calc(50% - 30px);
            margin-bottom: 0;
        }
    }
}

#page-document .blocks-group-wrapper > .block.teaser-listing.two-columns,
#page-document .blocks-group-wrapper > .block.news-listing.two-columns {
    @container (min-width: 1000px) and (max-width: 1400px) {
        .teaser-item {
            flex-direction: column;
            height: unset;

            .image-wrapper {
                width: unset;

                img {
                    height: 100%;
                    width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                    background-color: $white;
                }
            }

            .content {
                max-height: 240px;
            }
        }
    }
    @container (max-width: 999px) {
        .items {
            flex-direction: column;

            .teaser-listing-item {
                flex-basis: 100%;
                max-width: unset;
            }
        }
    }
}

#page-document .blocks-group-wrapper > .block.listing {

    .listing-preview-link .link.container {
        margin-top: 60px;
        margin-right: 0;
    }

    &.two-columns {
        @container(min-width: 1000px) {
            .listing-preview-link .block.__link {
                max-width: calc(50% - 30px);
                margin-left: auto;
            }
        }
    }
}

// pagination
#page-document .blocks-group-wrapper > .block.project-listing,
#page-document .blocks-group-wrapper > .block.teaser-listing,
#page-document .blocks-group-wrapper > .block.news-listing,
#page-document .blocks-group-wrapper > .block.event-listing {
    @media(min-width: $computer-breakpoint) {
        .pagination-wrapper-top, .pagination-wrapper-bottom {
            justify-content: flex-start;
        }
    }
}

#page-document .blocks-group-wrapper > .block.event-listing {
    @media(min-width: $computer-breakpoint) {
        .pagination-wrapper-top, .pagination-wrapper-bottom {
            @include default-container-width();
            @include adjustMarginsToContainer($default-container-width);
        }
    }
}

#page-document .blocks-group-wrapper > .block.teaser-listing {
    .teaser-listing-info {
        display: none;
    }
}