.container {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 50% auto;
  // justify-content: space-between;
  // gap: 20px;
  align-items: center;
  grid-row: 5 !important;
  color: #052e37;
  font: normal normal normal 20px / 32px 'SourceSans';
  margin-top: 90px;
  // background-color: #f3f5f5;
  // border-left: 2rem solid #bfce40;
}

.tableContainer {
  background-color: #f3f5f5;
  padding: 40px 60px 60px 40px;
  //   max-width: 200px; /* Adjust as needed */
  p {
    font: 34px / 54px 'WeissenhofGrotesk';
    font-weight: 700;
    margin: 0 !important;
    padding: 9px !important;
    word-break: keep-all;
  }
}

.textContainer {
  padding: 0 20px;
  grid-row: 2;
  h1 {
    font: 34px / 54px 'WeissenhofGrotesk';
    font-weight: 700;
  }
  p {
    color: #052e37;
    font: normal normal normal 20px / 32px 'SourceSans';
  }
}

.imageContainer {
  //   max-width: 150px; /* Adjust as needed */
  // max-width: 500px;
  cursor: pointer;
}
.imageContainer img {
  width: 100%; /* Ensures the image takes up the full width of the container */
  height: 100%; /* Ensures the image takes up the full height of the container */
  object-fit: cover; /* Ensures the image covers the entire container while maintaining aspect ratio */
}

.infoTable {
  // width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.infoTable th,
.infoTable td {
  text-align: left;
  padding: 10px;
  // border: 1px solid #ccc;
}

.infoTable th {
  font-weight: bold;
  // background-color: #f9f9f9;
}

@media (max-width: 1200px) {
  .container {
    grid-template-columns: auto;
  }
}
@media (max-width: 768px) {
  .infoTable {
    flex-direction: column;
    display: flex;
    // align-items: center;
  }
  .infoTable th,
  .infoTable td {
    display: block;
  }
}
