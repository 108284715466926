@mixin button-styles {
    background-color: $midnight-green;
    font-weight: normal;
    color: $white;
    height: 60px;
    font-size: 20px;
    padding: 17px 20px;
    border: none;
    transition: background-color 100ms, color 100ms;

    &:focus,
    &:hover {
      background-color: $lime;
      color: $midnight-green;
    }
}

.block.__button .ui.button:not(.icon) {
    @include button-styles();
}

.block.__button .button.container {
    max-width: var(--default-container-width);
}
