.map-wrap {
  position: relative;
  width: 100%;
  /*height: calc(100vh - 77px);*/ /* calculate height of the screen minus the heading */
  height: 50vh;
}

.map {
  /* position: absolute; */
  width: 100%;
  height: 50vh;
}
.weatherforecast_simplemap {
  border-top: 3px solid #bfce40;
}

.marker2 {
  /* background-image: url('https://maplibre.org/maplibre-gl-js/docs/assets/washington-monument.jpg'); */
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.temperature_parent {
  display: flex;
  /* justify-content: center; */
  justify-content: center;
  gap: 5px;
}

.temperature {
  font-size: 14px;
  margin: 0;
  padding: 0;
  /* border:1px solid black; */
  background-color: #427a97f0;
}

.warnstatus {
  font-style: italic;
}
.maplibregl-popup-close-button {
  font-size: 25px;
  margin: 0 5px;
  font-weight: bold;
}

/* für die warnungen */
.bluefilter {
  filter: invert(43%) sepia(99%) saturate(1641%) hue-rotate(200deg)
    brightness(99%) contrast(94%);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #4b5563;
  color: #fff;
  text-align: center;
  border-radius: 1px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #4b5563 transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.zoom-controls {
  margin-bottom: 10px; /* Adds a small margin below the buttons */
  right: 0;
  position: absolute;
  z-index: 2;
  padding-top: 1rem;
  bottom: 0;
}
.zoom-controls-ctrl {
  display: flex; /* Arranges buttons horizontally */
  justify-content: space-between; /* Spreads buttons evenly */
  flex-direction: column;
  float: right;
  margin: 10px 10px 0 0;
  /* background-color: #052e37; */
  border-radius: 4px;
}

.zoom-button {
  /* border: 1px solid #ddd;
  border-radius: 4px; */
  border: unset;
  margin-top: 0.1rem;
  padding: 0;
  cursor: pointer;
  background-color: #052e37;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoomButtonBox {
  height: 14px;
  width: 14px;
}
/* SVG Icon CSS */
.svg_icon {
  height: 24px; /* Adjust size as needed */
  width: 24px; /* Adjust size as needed */
  fill: white; /* Ensure the SVG color matches your design */
}

.zoom-button:hover {
  background-color: #bfce40; /* Slightly lighter background on hover */
}

.maplibregl-popup-anchor-bottom .maplibregl-popup-tip {
  border-top-color: #052e37 !important;
}

@media (max-width: 940px) {
  .zoom-controls {
    display: none;
  }
}
