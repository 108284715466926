.customDropdown {
  position: relative;
  /* width: 200px; */
  color: #052e37;
  z-index: 11;
  font: normal normal normal 20px/32px 'SourceSans';
}
/* .mainTitle {
  width: 200px;
} */
.dropdownHeader {
  height: 60px;
  width: 260px;
  /* width: 260px; */
  padding: 17px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  background-color: #e6eaeb;
  cursor: pointer;
}

.dropdownList {
  position: absolute;

  top: 100%;
  left: 0;
  right: 0;
  background-color: #e6eaeb;
  /* border: 1px solid #ccc; */
  border-top: 1px solid #ccc;
  /* border-top: none; */
  /* border-radius: 0 0 4px 4px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdownItem {
  /* padding: 8px; */
  border-bottom: #9babaf 1px solid;
  cursor: pointer;
  padding-left: 20px !important;
  padding-top: 12.5px !important;
  padding-bottom: 12.5px !important;
}

.dropdownItem:hover {
  background-color: #fff;
}
.marginRight {
  margin-right: 9px;
}

@media (max-width: 940px) {
  .mainTitle {
    width: auto;
  }
}
