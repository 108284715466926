.event-view .block.__button .button.container.event-overview-link {
    margin-bottom: 0;
    margin-top: 0;

    a > button.ui.button {
        white-space: nowrap;
    }
}

.top-overview-button .block.__button .button.container.event-overview-link {
    margin-top: 60px;
    margin-bottom: 20px;
}

.event-view .block.__button:has(.button.container.event-overview-link) {
    margin-bottom: 0;
}

.event-view h1.documentFirstHeading {
    margin-top: 0!important;
}

@container(max-width: 1000px) {
    .block.eventMetadata + .__link, .event-overview-link {
        margin-right: 40px!important;
        margin-left: 40px!important;
    }
}
.block.eventMetadata {
    &:has(+ .__link) {
        margin-bottom: 20px!important;
    }

    .details-container {
        border: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;

        .event-button {
            @extend .block;
            @extend .__link;
        }

        .content-container {
            flex-direction: column;
            gap: 25px;
            padding: 44px 60px;
            background-color: $midnight-green-5;

            .event-details {
                display: flex;
                justify-content: space-between;
                margin: 0!important;
                flex-wrap: wrap;
                gap: 30px;

                .event-title {
                    margin-bottom: 0;
                    min-width: 30%;

                    .event-heading {
                        text-transform: none;
                        font-size: 16px;
                        letter-spacing: normal;
                    }

                    .event-detail {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
